import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {
  GridFilterConfig,
  GridFilterItemComponent,
  GridFilterItemComponentFilter,
  GridFilterModelItem
} from "../../../../../models/grid.model";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {ENUM_ENTRIES_DATA, OptionEntry} from "../grid-filter-item-set/grid-filter-item-set.component";

@Component({
  selector: 'app-grid-filter-item-radio',
  templateUrl: './grid-filter-item-radio.component.html'
})
export class GridFilterItemRadioComponent implements OnInit, GridFilterItemComponent {
  @Input() filterConfig: GridFilterConfig;
  @Input() filterModel: GridFilterModelItem;
  @Output() applyFilter = new EventEmitter<void>();

  searchString: string;
  options: OptionEntry[] = [];
  filteredOptions: OptionEntry[] = [];

  setFilterForm: FormGroup = this.fb.group({});

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.options = [];
    const values = this.filterConfig.values;
    const valueFmt = this.filterConfig.valueFormatter;
    const enumEntries = ENUM_ENTRIES_DATA[this.filterConfig.key];
    if (Array.isArray(values)) {
      this.options = values.map(d => {
        const key = d.toString();
        if (enumEntries && enumEntries[key]) {
          const entry = enumEntries[key];
          return {
            key: this.translate(entry.key),
            value: key,
            icon: entry.iconData?.icon,
            iconClass: entry.iconData?.colorClass,
            closed: false,
          }
        } else {
          return {
            key: this.translate(key),
            value: key,
            closed: false,
          }
        }
      });
      this.setFormGroup(this.options);
      this.filteredOptions = this.filterOptions();
    } else if (typeof values === 'function' && typeof valueFmt === 'function') {
      values({
        success: (idents: any[]) => {
          this.options = idents.map(d => {
            const closed = d['closed'] === true;
            return {
              key: this.translate(valueFmt({value: d})),
              value: d['id'] || d,
              icon: closed ? 'report' : undefined,
              closed,
            }
          });
          this.setFormGroup(this.options);
          this.filteredOptions = this.filterOptions();
        }
      });
    }
  }

  selectedEntry(entry: OptionEntry) {
    Object.entries(this.setFilterForm.controls).forEach(([key, ctrl]) => {
      ctrl.setValue(entry.key == key);
    })
  }

  getModel(): GridFilterItemComponentFilter | undefined {
    const newValues = Object.entries(this.setFilterForm.value)
      .map(([key, value]) =>
        (value ? this.options.find(o => o.key === key)?.value : null))
      .filter((item) => item !== null);
    const model = newValues.length === 0 ? undefined : {
      filterType: 'set',
      values: newValues,
    };
    return {config: this.filterConfig, model, form: this.setFilterForm};
  }

  private filterOptions() {
    if (this.searchString && this.searchString.trim().length > 0) {
      const str = this.searchString.toLowerCase();
      return this.options
        .filter(option =>
          option.key.toLowerCase().includes(str)
          || option.value.toString().toLowerCase().includes(str)
        );
    }
    return this.options.slice();
  }

  onSearchStringUpdated() {
    this.filteredOptions = this.filterOptions();
  }

  private translate(headerName: string) {
    const key = headerName.replace(/\./g, '');
    return this.translateService.instant(key);
  }

  /**
   * Creates a form group for the set filter
   * Has to be called after the options are set
   * Expects unique keys in the options - the form will create a control for each unique key
   * @param options
   */
  setFormGroup(options: OptionEntry[]) {
    options.forEach((option) => {
      this.setFilterForm.addControl(option.key, this.fb.control(
        this.filterModel
          ? this.filterModel.values.findIndex((value) => value === option.value) !== -1
          : false
      ));
    });
  }

  onSelectAllChanged(event: MatCheckboxChange) {
    this.filteredOptions.forEach(option => {
      this.setFilterForm.controls[option.key].setValue(event.checked);
    })
  }

  onApplyFilter() {
    this.applyFilter.next();
  }
}
