<div class="overview-container">
  <app-card class="">
    <div card-header>
      {{(isDecentralized ? 'decentralized' :  cached ? 'monitoringCachedAt' : 'monitoringRealtime') | translate}}{{createdAt}}
    </div>
    <mat-button-toggle-group
      card-action
      name="cached"
      aria-label="Cached Status"
      *ngIf="!isDecentralized"
      [(ngModel)]="cached">
      <mat-button-toggle [value]="true">
        {{'monitoringCached' | translate}}
      </mat-button-toggle>
      <mat-button-toggle [value]="false">
        {{'monitoringRealtime' | translate}}
      </mat-button-toggle>
    </mat-button-toggle-group>
    <div class="campaign-container" card-body>
      <div class="campaign-values">
        <app-block-value
          valueType="percentage"
          blockTitle="contacted"
          [number]="monitoringCampaign.clientContactsPercentage"
        />
        <app-block-value
          valueType="percentage"
          blockTitle="participationPercentage"
          [number]="monitoringCampaign.participationPercentage"
        />
        <app-block-value
          valueType="percentage"
          blockTitle="viewedRate"
          [number]="monitoringCampaign.clientViewedPercentage"
        />
        <app-block-value
          valueType="percentage"
          blockTitle="executedRate"
          [number]="monitoringCampaign.executionsPercentage"
        />
      </div> <!--campaign-values-->
      <app-card [showHeader]="false" class="card-overview">
        <app-grid
          card-body
          #grid
          tableId="monitoring-campaign-overview"
          [data]="data"
          [columnDefs]="columnDefs"
          [gridOptions]="gridOptions"
        ></app-grid>
      </app-card>
      <app-card class="card-movements" [showHeader]="false">
        <div card-body>
          <div class="tab-group">
            <mat-button-toggle-group name="movements" [(ngModel)]="currentMovementTab">
              <mat-button-toggle [value]="0">
                {{ 'portfolioMovementsSummary' | translate }}
              </mat-button-toggle>
              <mat-button-toggle [value]="1">
                {{ 'portfolioMovements' | translate }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <app-grid
            tableId="movementsSummaryTable"
            *ngIf="currentMovementTab == 0"
            [rowData]="movementsSummaryData"
            [columnDefs]="movementsSummaryColumnDefs"
            [gridOptions]="movementsSummaryGridOptions"
          ></app-grid>
          <app-grid
            #movementsGrid
            tableId="movementsTable"
            *ngIf="currentMovementTab == 1"
            [data]="movementsData"
            [columnDefs]="movementsColumnDefs"
            [gridOptions]="movementsGridOptions"
          ></app-grid>
        </div>
      </app-card>
      <app-card [showHeader]="false" class="card-charts">
        <app-monitoring-charts-card
          card-body
          [monitoringType]="monitoringTypes.campaign"
          [monitoringCampaign]="monitoringCampaign"
          [cached]="cached"
          class="chart-card"
        ></app-monitoring-charts-card>
      </app-card>
    </div>  <!-- campaign-container -->
  </app-card>
</div>
