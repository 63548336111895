import {ProgressSocket} from "./progress-socket";

export class MonitoringUpdateProgressSocket extends ProgressSocket<ProgressEvent> {
  constructor(accessToken: string) {
    super('monitoring/update', accessToken);
  }
}

export type ProgressType = 'COMPLETED' | 'STARTING' | 'UPDATING' | 'ERROR';

export interface ProgressEvent {
  message: string;
  type: ProgressType;
  progress?: number;
}
