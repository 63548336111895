import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MonitoringBucket, MonitoringService, UserInfo} from 'src/app/api/core';
import {GlobalService} from 'src/app/services/global.service';
import {GridComponent} from 'src/app/shared/grid/grid.component';
import {GridDataProvider} from 'src/app/shared/grid/data-source';
import {genNumberColumn, genPercentageNumberColumn, genUserEnumColumn,} from 'src/app/util/grid/grid-renderer.util';
import {Observable, Subscription} from 'rxjs';
import {ColDef, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {I18n} from '../../../services/i18n.service';
import {HtmlService} from "../../../services/html.service";
import {LabelBuilder} from "../../../util/label-builder";

@Component({
  selector: 'app-monitoring-relationship-manager',
  templateUrl: './monitoring-relationship-manager.component.html',
})
export class MonitoringRelationshipManagerComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid: GridComponent;
  @Input() refreshEvent: Observable<string>;
  @Input() selectedBucketSubject: Observable<MonitoringBucket>;

  campaignsRelationshipManagers: UserInfo[];
  selectedBucket: MonitoringBucket;

  data: GridDataProvider;

  columnDefs: ColDef[] = [
    {
      ...genUserEnumColumn(
        'username',
        I18n.getColName('name'),
        this.fetchCampaignRelationshipManagers.bind(this),
        () => this.campaignsRelationshipManagers
      ),
      valueFormatter: (r) => (r.data.username.trim()) ? `${r.data.name} (${r.data.username})` : r.data.name,
    },
    genNumberColumn(
      'portfolioCount',
      I18n.getColName('portfolios'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0)
    ),
    {
      ...genPercentageNumberColumn(
        'clientContactsPercentage',
        I18n.getColName('contacted'),
        this.globalService,
      ),
    },
    genNumberColumn(
      'clientContacts',
      I18n.getColName('contacts'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0)
    ),
    {
      ...genPercentageNumberColumn(
        'executionsPercentage',
        I18n.getColName('executedRate'),
        this.globalService,
      ),
      hide: true,
    },
    genNumberColumn(
      'executions',
      I18n.getColName('executions'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0),
    ),
    {
      ...genPercentageNumberColumn(
        'noActionsPercentage',
        I18n.getColName('noActionsRate'),
        this.globalService,
      ),
    },
    genNumberColumn(
      'noActions',
      I18n.getColName('noActions'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0)
    ),
    {
      ...genPercentageNumberColumn(
        'pendingActionsPercentage',
        I18n.getColName('pendingActionsRate'),
        this.globalService,
      ),
    },
    genNumberColumn(
      'pendingActions',
      I18n.getColName('pendingActions'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0)
    ),
    genNumberColumn(
      'sellVolume',
      this.labelBuilder.labelWithCurrency('sellTradingVolume'),
      this.globalService
    ),
    genNumberColumn(
      'buyVolume',
      this.labelBuilder.labelWithCurrency('buyTradingVolume'),
      this.globalService
    ),
    {
      ...genNumberColumn(
        'totalVolume',
        this.labelBuilder.labelWithCurrency('totalTradingVolume'),
        this.globalService
      ),
    },
    {
      ...genPercentageNumberColumn(
        'clientViewedPercentage',
        I18n.getColName('viewedRate'),
        this.globalService,
      ),
    },
    genNumberColumn(
      'clientViewed',
      I18n.getColName('viewed'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0)
    ),
    genNumberColumn(
      'participationCount',
      I18n.getColName('participationCount'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0)
    ),
    {
      ...genPercentageNumberColumn(
        'participationPercentage',
        I18n.getColName('participationPercentage'),
        this.globalService
      ),
    },
    {
      ...genNumberColumn(
        'clientCount',
        I18n.getColName('clients'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0),
      )
    },
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent) => {
      this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
      this.gridApi = event.api;
    }
  };

  subscriptions: Subscription[] = [];
  gridApi: GridApi;

  constructor(
    protected translateService: TranslateService,
    protected monitoringService: MonitoringService,
    protected globalService: GlobalService,
    protected htmlService: HtmlService,
    protected labelBuilder: LabelBuilder,
  ) {
  }

  ngOnInit(): void {
    if (this.refreshEvent) {
      this.subscriptions.push(this.refreshEvent.subscribe(event => {
        if (event === 'refreshGrid') {
          this.grid.refresh();
        }
      }));
    }
    if (this.selectedBucketSubject) {
      this.subscriptions.push(
        this.selectedBucketSubject.subscribe(bucket => {
          this.selectedBucket = bucket;
          this.data = this.monitoringService.getRelationshipManagers.bind(
            this.monitoringService, this.selectedBucket?.id ?? 0
          );
          this.grid.updateDataSource(this.data, true);
          this.grid.reload();
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private fetchCampaignRelationshipManagers(params: any) {
    this.monitoringService.getAllRelationshipManagers(this.selectedBucket.id)
      .subscribe((users) => {
        this.campaignsRelationshipManagers = users;
        params.success(users.map((d) => d.username));
      });
  }

}
