import {Component, OnDestroy, OnInit} from '@angular/core';
import {Product, ProductService} from 'src/app/api/core';
import {GlobalService} from 'src/app/services/global.service';
import {GridDataProvider} from 'src/app/shared/grid/data-source';
import {TranslateService} from '@ngx-translate/core';
import {ColDef, GridApi, GridOptions, GridReadyEvent, RowDoubleClickedEvent,} from 'ag-grid-community';
import {ECodeTables, EViewRoutes} from 'src/app/util/enum';
import {CodeTableService} from 'src/app/services/code-table.service';
import {
  genBooleanColumn,
  genCodeTableColumn,
  genPercentageNumberColumn,
  genTextColumn,
  genTranslatedTextColumn,
} from 'src/app/util/grid/grid-renderer.util';
import {Subscription} from 'rxjs';
import {I18n} from '../../../services/i18n.service';
import {GridResetEvent} from '../../../shared/grid/grid.component';
import {genLinkColumn} from "../../../shared/grid/cell-renderers/link.renderer";
import {genIconLabelColumn} from "../../../shared/grid/cell-renderers/icon-label.renderer";

/**
 * Component to display all products that can be filtered by tags.
 */
@Component({
  selector: 'app-product-catalogue',
  templateUrl: './product-catalogue.component.html',
})
export class ProductCatalogueComponent implements OnInit, OnDestroy {
  /**
   * Data Provider for Product grid
   */
  data: GridDataProvider = this.productService.getProducts.bind(
    this.productService
  );
  /**
   * Column definition for the "all product" grid
   */
  columnDefs: ColDef[] = [
    {
      ...genIconLabelColumn({
        field: 'isin',
        headerName: I18n.getColName('isin'),
        label: (data: Product) => data.isin,
        labelCallback: (data: Product) => `${EViewRoutes.productOverview}${data.id}`,
        icon: (data: Product) => data.link? 'open_in_new' : null,
        iconClass: 'link-icon',
        iconTooltip: 'Product Link',
        callback: (data: Product) => window.open(data.link, '_blank'),
        align: 'right',
      }),
      sortable: true,
      floatingFilter: true,
      suppressColumnsToolPanel: false,
    },
    genTextColumn('name', I18n.getColName('name')),
    genTranslatedTextColumn('country', I18n.getColName('country')),
    genCodeTableColumn({
      field: 'type',
      headerName: I18n.getColName('type'),
      observable: this.codeTableService.getCodeTable(ECodeTables.assetType),
    }),
    genCodeTableColumn({
      field: 'assetClass',
      headerName: I18n.getColName('class'),
      observable: this.codeTableService.getCodeTable(ECodeTables.assetClass),
    }),
    genTextColumn('currency.ident', I18n.getColName('referenceCurrency')),
    genCodeTableColumn({
      field: 'rating',
      headerName: I18n.getColName('rating'),
      observable: this.codeTableService.getCodeTable(ECodeTables.rating),
    }),
    {
      ...genPercentageNumberColumn(
        'performance.ytd',
        I18n.getColName('performanceYtd'),
        this.globalService,
        true,
        true
      ),
      hide: true,
    },
    {
      ...genPercentageNumberColumn(
        'performance.all',
        I18n.getColName('performanceAll'),
        this.globalService,
        true,
        true
      ),
      hide: true,
    },
    genBooleanColumn(
      'closed',
      I18n.getColName('CLOSED'),
      this.translateService
    ),
  ];
  /**
   * Grid options for "all products"
   */
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.navigateTo(`${EViewRoutes.productOverview}${event.data.id}`),
    onGridReady: (event: GridReadyEvent) => {
      this.gridReady(event);
      this.subscription = I18n.getColumns(this.translateService, event.api);
    },
  };

  subscription: Subscription;

  constructor(
    protected productService: ProductService,
    protected globalService: GlobalService,
    protected translateService: TranslateService,
    protected codeTableService: CodeTableService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Routes user to clicked view
   * @param route Where the user should be routed to. Comes from HTML
   */
  navigateTo(route: string): void {
    this.globalService.navigate(route);
  }

  gridReady(event: GridReadyEvent): void {
    this.gridFilterReset({ api: event.api });
  }

  gridFilterReset(event: GridResetEvent) {
    this.applyDefaultFilters(event.api);
  }

  private applyDefaultFilters(api: GridApi) {
    const closedFilterModel = api.getColumnFilterModel('closed');
    if (!closedFilterModel) {
      api.setColumnFilterModel('closed', { values: ['false'] })
        .then(() => api.onFilterChanged());
    }
  }
}
