<div class="page-content-container">
  <app-tabs-panel
    [tabs]="tabs"
    [defaultTab]="defaultTab"
    (tabChanged)="onTabChanged($event)"
  >
    <div class="colored-menu-trigger"
         tab-action
         [matMenuTriggerFor]="hubMenu"
         *ngIf="hasHubs && ((isAdmin && !isTabDecentralizedOverview) || !isDecentralizedMonitor) "
      >
      <app-config-icon
        iconIdentifier="storage"
        [matTooltip]="'hub' | translate"
      ></app-config-icon>
      <div>{{ selectedHub?.name || '' }}</div>
      <app-config-icon iconIdentifier="expand_more"></app-config-icon>
      <mat-menu #hubMenu="matMenu" class="hub-menu">
        <ng-container *ngIf="hubs.length > 0">
          <button
            mat-menu-item
            *ngFor="let hub of hubs"
            class="mat-menu-item-flex"
            (click)="onSelectHub(hub)"
          >
            <span>{{ hub.name }}</span>
          </button>
        </ng-container>
      </mat-menu>
    </div>
    <div class="colored-menu-trigger"
         tab-action
         [matMenuTriggerFor]="bucketMenu"
         *ngIf="(isAdmin && !isTabDecentralizedOverview) || !isDecentralizedMonitor"
    >
      <app-config-icon
        iconIdentifier="storage"
        [matTooltip]="'buckets' | translate"
      ></app-config-icon>
      <div>{{ selectedBucket?.name || '' }}</div>
      <app-config-icon iconIdentifier="expand_more"></app-config-icon>
      <mat-menu #bucketMenu="matMenu" class="hub-menu">
        <ng-container *ngIf="buckets.length > 0">
          <button
            mat-menu-item
            *ngFor="let bucket of buckets"
            class="mat-menu-item-flex"
            (click)="onSelectBucket(bucket)"
          >
            <span>{{ bucket.name }}</span>
          </button>
        </ng-container>
      </mat-menu>
    </div>
    <button
      mat-flat-button
      tab-action
      color="primary"
      *ngIf="activeTab && activeTab === tabs[0].text && !monitoringCampaign && protectedActions.updateMonitoring | protectedAction"
      (click)="handleUpdate()"
    >
      <app-config-icon
        iconIdentifier="refresh"
        class="color-white"
      ></app-config-icon>
      <span>{{
        'monitoringUpdateButton' | translate
        }}</span>
    </button>
    <app-config-icon
      iconIdentifier="refresh"
      [tooltip]="'refresh' | translate"
      (click)="gridRefresh()"
      tab-action
    >
    </app-config-icon>
  </app-tabs-panel>
  <div *ngIf="activeTab === tabs[0].text && !monitoringCampaign" class="tab-container">
    <app-monitoring-campaign
      [refreshEvent]="refreshSubject"
      [selectedBucketSubject]="selectedBucketSubject"
    ></app-monitoring-campaign>
  </div>
  <div *ngIf="activeTab === tabs[0].text && monitoringCampaign" class="tab-container">
    <app-monitoring-campaign-overview [monitoringCampaign]="monitoringCampaign"
      [refreshEvent]="refreshSubject"></app-monitoring-campaign-overview>
  </div>
  <div *ngIf="activeTab === tabs[1].text" class="tab-container">
    <app-monitoring-relationship-manager
      [refreshEvent]="refreshSubject"
      [selectedBucketSubject]="selectedBucketSubject"
    ></app-monitoring-relationship-manager>
  </div>
  <div *ngIf="activeTab === tabs[2].text" class="tab-container">
    <app-monitoring-campaign-decentralized
      [refreshEvent]="refreshSubject"
      [selectedBucketSubject]="selectedBucketSubject">
    </app-monitoring-campaign-decentralized>
  </div>
</div>
