import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChildren,} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {GridApi} from 'ag-grid-community';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Campaign,} from 'src/app/api/core';
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckbox, MatCheckboxDefaultOptions,} from '@angular/material/checkbox';
import {GridFilterModel} from "../../../../models/grid.model";
import {ActionType} from "../../../../campaign/views/campaign-overview/campaign-actions-list-utils";
import {DataService, SelectionStatus} from "../../../../services/data.service";
import {PipeLogicService} from "../../../../services/pipe.logic.service";

@Component({
  selector: 'app-table-selection-bar',
  templateUrl: './table-selection-bar.component.html',
  providers: [
    // We set the checkbox click action to no operation so that we can handle it manually
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions,
    },
  ],
})
export class TableSelectionBarComponent implements OnInit, OnDestroy {
  @ViewChildren('selectAllCheckbox')
  selectAllCheckbox: MatCheckbox;

  @Input() isHierarchyList = false;
  @Input() gridApi: GridApi;
  @Input() itemLabelRef?: string;
  @Input() filterModelSubject: BehaviorSubject<GridFilterModel>;
  @Input() campaign: Campaign;
  @Input() searchPlaceholder: string | undefined = undefined;
  /**
   * Columns that are shown in the filter, even if they are hidden
   */
  @Input() importantColumns: string[] | undefined = undefined;

  @Output() selectionProcessing = new EventEmitter<boolean>();
  @Output() searchChanged = new EventEmitter<string>();
  @Output() selectAllClicked = new EventEmitter<boolean>();
  @Output() selectionCountChanged = new EventEmitter<number>();

  isEditableCampaign: boolean = false;
  someSelected = false;
  allSelected = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private dataService: DataService,
    private readonly pipeLogicService: PipeLogicService,
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.dataService.selectionStatus$.subscribe(status => this.onSelectionStatusChanged(status)),
    );
    this.isEditableCampaign = this.pipeLogicService.isCampaignEditablePipe(this.campaign.status);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onSelectionStatusChanged(status?: SelectionStatus) {
    this.selectionCountChanged.emit(status?.count ?? 0);
    this.allSelected = status?.all ?? false;
    this.someSelected = status?.some ?? false;
  }

  /**
   * Toggles the selection of all rows, it checks the selection state and avoids to use the checkbox state
   * since it is not reliable when the selection is done by code.
   */
  togglePageSelection(_: MouseEvent) {
    this.selectionProcessing.emit(true);
    this.selectAllCheckbox.checked = (this.someSelected || !this.allSelected);
    this.selectAllClicked.emit(this.selectAllCheckbox.checked);
  }

  getTooltip() {
    if (this.allSelected) {
      return this.translateService.instant('deselectAll');
    } else {
      return this.translateService.instant('selectAllPages');
    }
  }

  protected readonly ActionType = ActionType;

}
