import {Injectable, OnDestroy} from '@angular/core';
import * as Highcharts from 'highcharts';
import {EChartTypes, EFilterCategories, ERangeWeightFilter, EViewRoutes} from 'src/app/util/enum';
import {Subscription} from 'rxjs';
import {DataService} from './data.service';
import {FilterUtilsService} from './filter-utils.service';
import {ConfigService} from './config.service';
import {NotificationService} from './notification.service';
import {TranslateService} from '@ngx-translate/core';
import {Campaign, Story} from '../api/core';
import {FilterConfig, WeightRange} from '../models/filter.model';
import {PermissionService} from './permission.service';
import {EProtectedActions} from '../util/protected-actions';
import {UiFilterConfigService} from "./ui-filter-config.service";
import {isFilterRangeWeightFilterActivePipe} from "./filter-utils.service";

/**
 * Service for handling all the generic chart functions required to render the charts.
 */
@Injectable({
  providedIn: 'root',
})
export class ChartService implements OnDestroy {
  /**
   * Subscription to currently active filter
   */
  filterIdSubscription: Subscription;
  /**
   * currently active filter
   */
  currentFilter: FilterConfig;

  campaign: Campaign;

  story: Story;

  constructor(
    protected configService: ConfigService,
    protected dataService: DataService,
    protected filterService: FilterUtilsService,
    protected notificationService: NotificationService,
    protected translateService: TranslateService,
    protected permissionService: PermissionService,
    protected readonly filterConfigService: UiFilterConfigService,
  ) {
    this.filterIdSubscription = this.dataService.filter$.subscribe(
      (filter) => (this.currentFilter = filter)
    );
  }

  ngOnDestroy(): void {
    this.filterIdSubscription.unsubscribe();
  }

  /**
   * Generic function to generate the chart options for a specific chart type
   * @param chartType Chart type for which the options should be returned
   */
  getChartOptions(chartType: EChartTypes): Highcharts.Options {
    let options: Highcharts.Options;
    let seriesOptions: Highcharts.SeriesColumnOptions;
    // for each type get default chart options
    switch (chartType) {
      case EChartTypes.scatter:
        options = this.getDefaultScatterPlotChartOptions();
        break;
      case EChartTypes.assetClassBar:
      case EChartTypes.currencyBar:
      case EChartTypes.regionBar:
      case EChartTypes.sectorBar:
        options = this.getDefaultBarChartOptions(chartType);
        break;
      case EChartTypes.assetClassPie:
      case EChartTypes.currencyPie:
      case EChartTypes.regionPie:
      case EChartTypes.sectorPie:
      case EChartTypes.prodAssetClassPie:
      case EChartTypes.prodRegionPie:
      case EChartTypes.prodSectorPie:
      case EChartTypes.portfolioSuitabilityPie:
      case EChartTypes.actionSuitabilityPie:
      case EChartTypes.actionChannelPie:
      case EChartTypes.actionLanguagePie:
      case EChartTypes.homeParticipatedPie:
        options = this.getDefaultPieChartOptions(chartType);
        break;
      case EChartTypes.currentUserCampaignActionStatesPie:
        options = this.getDefaultPieAbsoluteValuesChartOptions(
          EChartTypes.currentUserCampaignActionStatesPie
        );
        break;
      case EChartTypes.campaignActionStatesPie:
        options = this.getDefaultPieAbsoluteValuesChartOptions(
          EChartTypes.campaignActionStatesPie
        );
        break;
      case EChartTypes.monitoringContactedPie:
        options = this.getDefaultPieChartOptions(
          EChartTypes.monitoringContactedPie
        );
        options.chart.height = this.relativeChartHeight();
        break;
      case EChartTypes.monitoringPortfolioRatePie:
        options = this.getDefaultPieChartOptions(
          EChartTypes.monitoringPortfolioRatePie
        );
        options.chart.height = this.relativeChartHeight();
        break;
      case EChartTypes.monitoringContacted3Pie:
        options = this.getDefaultPieChartOptions(
          EChartTypes.monitoringContactedPie
        );
        options.chart.height = this.relativeChartHeight();
        // provide special two slice colors
        // options.plotOptions.pie.colors = this.getMonitoringPieColors(3);
        break;
      case EChartTypes.monitoringExecutedPie:
        options = this.getDefaultPieChartOptions(
          EChartTypes.monitoringExecutedPie
        );
        options.chart.height = this.relativeChartHeight();
        break;
      case EChartTypes.monitoringExecutedPiePerSentAction:
        options = this.getDefaultPieChartOptions(
          EChartTypes.monitoringExecutedPiePerSentAction
        );
        options.chart.height = this.relativeChartHeight();
        break;
      case EChartTypes.monitoringPie:
        options = this.getDefaultPieChartOptions(EChartTypes.monitoringPie);
        options.lang.noData = 'No data to show';
        options.chart.height = this.relativeChartHeight();
        break;
      case EChartTypes.monitoringTradingVolumeBar:
        // TODO might need to change function and series name
        options = this.getDefaultHistogramChartOptions(
          EChartTypes.monitoringTradingVolumeBar
        );
        // special rounded borders (only top side) for value histogram + reset color
        options.plotOptions.column = {
          ...options.plotOptions.column,
          color: null,
          borderRadiusTopLeft: 3,
          borderRadiusTopRight: 3,
        } as Highcharts.PlotColumnOptions;
        // set special colors by point
        seriesOptions = options.series[0] as Highcharts.SeriesColumnOptions;
        seriesOptions.colorByPoint = true;
        seriesOptions.colors = this.getMonitoringBarColors();
        break;
      case EChartTypes.monitoringEarningsBar:
        // TODO might need to change function and series name
        options = this.getDefaultHistogramChartOptions(
          EChartTypes.monitoringEarningsBar
        );
        // special rounded borders (only top side) for value histogram + reset color
        options.plotOptions.column = {
          ...options.plotOptions.column,
          color: null,
          borderRadiusTopLeft: 3,
          borderRadiusTopRight: 3,
        } as Highcharts.PlotColumnOptions;
        // set special colors by point
        seriesOptions = options.series[0] as Highcharts.SeriesColumnOptions;
        seriesOptions.colorByPoint = true;
        seriesOptions.colors = this.getMonitoringBarColors();
        break;
      case EChartTypes.monitoringHoldingLineChart:
        options = this.getDefaultLineChartOptions();
        break;
      default:
        options = {};
    }
    const accessibility: Highcharts.AccessibilityOptions = { enabled: false };
    options.accessibility = accessibility;
    options.noData.position.align = 'center';
    return options;
  }

  init(story: Story, campaign: Campaign): ChartService {
    this.story = story;
    this.campaign = campaign;
    return this;
  }

  /**
   * Function to generate default scatter plot chart options.
   * {@link https://api.highcharts.com/highcharts/}
   */
  private getDefaultScatterPlotChartOptions(): Highcharts.Options {
    const chartOptions: Highcharts.Options = {};

    chartOptions.chart = {
      type: 'scatter',
      zooming: {
        type: 'xy',
      },
      events: {
        selection: (event) => {
          // filter based on selection
          this.onSelectFilterSubmit(event, EChartTypes.scatter);
          // return false to disable zoom
          return false;
        },
      },
    };

    chartOptions.title = {
      text: '',
    };

    chartOptions.legend = {
      enabled: false,
    };

    chartOptions.tooltip = {
      enabled: false,
    };

    chartOptions.xAxis = [
      {
        title: {
          text: this.translateService.instant('risk(cVar)'),
        },
        ceiling: this.configService.getScatterChartRiskCeiling(),
        floor: this.configService.getScatterChartRiskFloor(),
        gridLineWidth: 1,
        plotLines: [
          {
            color: '#8e95a4',
            width: 1,
            value: 0,
            zIndex: 1,
          },
        ],
      },
    ];

    chartOptions.yAxis = [
      {
        title: {
          text: this.translateService.instant('performance(1y)'),
        },
        ceiling: this.configService.getScatterChartReturnCeiling(),
        floor: this.configService.getScatterChartReturnFloor(),
        plotLines: [
          {
            color: '#8e95a4',
            width: 1,
            value: 0,
            zIndex: 1,
          },
        ],
        minPadding: 0,
        maxPadding: 0,
      },
    ];

    chartOptions.series = [
      // scatter series of portfolios
      {
        type: 'scatter',
        name: 'Historical Risk / Return',
        keys: ['name', 'x', 'y', 'description'],
        marker: {
          symbol: 'circle',
          radius: 3,
          fillColor: {
            radialGradient: {
              cx: 0.5,
              cy: 0.5,
              r: 1,
            },
            stops: [
              [0, this.configService.getPrimaryColor()],
              [0.5, this.configService.getPrimaryColor()],
              [
                1,
                new Highcharts.Color(this.configService.getPrimaryColor())
                  .brighten(0.5)
                  .get('rgb'),
              ],
            ],
          },
          states: {
            select: {
              fillColor: this.configService.getThridColor(),
              lineWidth: 0,
            },
          },
        },
        enableMouseTracking: false,
      } as unknown as Highcharts.SeriesScatterOptions,
      // scatter series for fixed model portfolios
      {
        type: 'scatter',
        name: 'MPFs',
        keys: ['name', 'x', 'y'],
        data: [],
        marker: {
          symbol: 'circle',
          radius: 18,
          fillColor: {
            radialGradient: {
              cx: 0.5,
              cy: 0.5,
              r: 1,
            },
            stops: [
              [0, this.configService.getDisableColor()],
              [
                0.25,
                new Highcharts.Color(this.configService.getDisableColor())
                  .setOpacity(0.8)
                  .get('rgba'),
              ],
              [
                0.5,
                new Highcharts.Color(this.configService.getDisableColor())
                  .setOpacity(0.3)
                  .get('rgba'),
              ],
              [
                0.75,
                new Highcharts.Color(this.configService.getDisableColor())
                  .setOpacity(0.05)
                  .get('rgba'),
              ],
              [
                1,
                new Highcharts.Color(this.configService.getDisableColor())
                  .setOpacity(0)
                  .get('rgba'),
              ],
            ],
          },
        },
        enableMouseTracking: false,
      } as unknown as Highcharts.SeriesScatterOptions,
    ];

    chartOptions.lang = {
      loading: 'Loading ...',
      noData: 'No portfolios match the current filter criteria',
    };

    // Nodata module settings
    chartOptions.noData = {
      style: { fontSize: '16px', color: 'gray' },
      position: { align: 'center' },
    };

    return chartOptions;
  }

  /**
   * Function to generate default pie chart options.
   * {@link https://api.highcharts.com/highcharts/}
   */
  private getDefaultPieChartOptions(
    chartType: EChartTypes
  ): Highcharts.Options {
    const chartOptions: Highcharts.Options = {};
    chartOptions.chart = {
      type: 'pie',
    };

    chartOptions.title = {
      text: '',
    };

    chartOptions.plotOptions = {
      pie: {
        innerSize: '60%',
        dataLabels: {
          enabled: false,
        },
        // get pie colors from helper array
        colors: this.configService.getGeneralPieColors(),
        showInLegend: true,
        point: {
          events: {
            legendItemClick: (e) => {
              e.preventDefault();
            },
            click: (event) => {
              this.dataService.setPieClick(event);
              return false;
            },
          },
        },
      },
    };
    // SET COLORS
    switch (chartType) {
      case EChartTypes.actionSuitabilityPie:
      case EChartTypes.portfolioSuitabilityPie:
        chartOptions.plotOptions.pie.colors =
          this.configService.getSuitabilityColors();
        break;
      case EChartTypes.currentUserCampaignActionStatesPie:
      case EChartTypes.campaignActionStatesPie:
      case EChartTypes.monitoringPortfolioRatePie:
        chartOptions.plotOptions.pie.colors =
          this.configService.getActionStatesColors();
        break;
      case EChartTypes.monitoringContactedPie:
      case EChartTypes.monitoringExecutedPie:
      case EChartTypes.monitoringExecutedPiePerSentAction:
        chartOptions.plotOptions.pie.colors =
          this.configService.getYesNoPieColors();
        break;
      case EChartTypes.homeParticipatedPie:
        chartOptions.plotOptions.pie.colors =
          this.configService.getYesNoHomePieColors();
        break;
      default:
        break;
    }

    chartOptions.tooltip = {
      // need to disable lint rule since this is the only way to do it
      // eslint-disable-next-line object-shorthand
      formatter: function () {
        // the scope "this" shows to the formatter not the component
        const self: any = this;
        return (
          '<span style="color:' +
          self.point.color +
          '">●</span> ' +
          self.point.name +
          ': <b>' +
          self.percentage.toFixed(2) +
          '%</b><br/>'
        );
      },
    };
    chartOptions.series = [
      {
        type: 'pie',
        name: chartType.toString(),
        data: [],
      } as Highcharts.SeriesPieOptions,
    ];

    chartOptions.lang = {
      loading: 'Loading ...',
      noData: 'No portfolios match the current filter criteria',
    };

    // Nodata module settings
    chartOptions.noData = {
      style: { fontSize: '16px', color: 'gray' },
      position: { align: 'left' },
    };
    return chartOptions;
  }

  /**
   * Function to generate default pie chart options.
   * {@link https://api.highcharts.com/highcharts/}
   */
  private getDefaultPieAbsoluteValuesChartOptions(
    chartType: EChartTypes
  ): Highcharts.Options {
    const chartOptions = this.getDefaultPieChartOptions(chartType);
    chartOptions.tooltip = {
      // need to disable lint rule since this is the only way to do it
      // eslint-disable-next-line object-shorthand
      formatter: function () {
        // the scope "this" shows to the formatter not the component
        const self: any = this;
        return (
          '<span style="color:' +
          self.point.color +
          '">●</span> ' +
          self.point.name +
          ': <b>' +
          self.point.options.y +
          '</b><br/>'
        );
      },
    };
    return chartOptions;
  }

  /**
   * Function to generate default histogram chart options.
   * {@link https://api.highcharts.com/highcharts/}
   */
  private getDefaultHistogramChartOptions(
    chartType: EChartTypes
  ): Highcharts.Options {
    const chartOptions: Highcharts.Options = {};

    chartOptions.chart = {
      type: 'column',
    };

    chartOptions.title = {
      text: '',
    };

    chartOptions.yAxis = [
      {
        title: {
          text: '',
        },
        min: 0,
      },
    ];

    chartOptions.legend = {
      enabled: false,
    };

    chartOptions.plotOptions = {
      column: {
        pointPadding: 0,
        borderWidth: 1,
        groupPadding: 0,
        shadow: false,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, this.configService.getPrimaryColor()],
            [0.25, this.configService.getPrimaryColor()],
            [
              1,
              new Highcharts.Color(this.configService.getPrimaryColor())
                .brighten(0.2)
                .get('rgb'),
            ],
          ],
        } as Highcharts.GradientColorObject,
      },
    };

    let chartName: string;
    switch (chartType) {
      case EChartTypes.monitoringTradingVolumeBar:
        chartName = this.translateService.instant('tradingVolume');
        break;
      case EChartTypes.monitoringEarningsBar:
        chartName = this.translateService.instant('turnover');
        break;
      default:
        break;
    }

    chartOptions.series = [
      {
        type: 'column',
        name: chartName,
        data: [],
      } as Highcharts.SeriesColumnOptions,
    ];

    chartOptions.lang = {
      loading: 'Loading ...',
      noData: 'No portfolios match the current filter criteria',
    };

    // Nodata module settings
    chartOptions.noData = {
      style: { fontSize: '16px', color: 'gray' },
      position: { align: 'left' },
    };

    return chartOptions;
  }

  private getFilterCategory(chartType: EChartTypes): EFilterCategories {
    switch (chartType) {
      case EChartTypes.assetClassBar:
        return EFilterCategories.assetClass;
      case EChartTypes.currencyBar:
        return EFilterCategories.currency;
      case EChartTypes.regionBar:
        return EFilterCategories.region;
      case EChartTypes.sectorBar:
        return EFilterCategories.sector;
      default:
        return null;
    }
  }

  /**
   * Function to generate default bar chart options.
   * {@link https://api.highcharts.com/highcharts/}
   */
  private getDefaultBarChartOptions(
    chartType: EChartTypes
  ): Highcharts.Options {
    const chartOptions: Highcharts.Options = {};

    chartOptions.chart = {
      type: 'column',
    };

    chartOptions.title = {
      text: '',
    };

    chartOptions.legend = {
      enabled: false,
    };

    chartOptions.tooltip = {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.count}</b> ({point.formattedY}%)<br/>',
      shared: true,
    };

    chartOptions.plotOptions = isFilterRangeWeightFilterActivePipe(
      this.filterConfigService,
      ERangeWeightFilter.weight,
      this.getFilterCategory(chartType)
    ) ? {
      column: {
        stacking: 'normal',
        point: {
          events: {
            click: (event) => {
              // filter based on selection
              this.onSelectFilterSubmit(event, chartType);
              // return false to disable zoom
              return false;
            },
          },
        },
      },
      series: {
        cursor: 'pointer',
      },
    } : {
      column: {
        stacking: 'normal',
      },
    }


    chartOptions.yAxis = {
      title: {
        text: '',
      },
      min: -100,
      max: 100,
    };

    // bar charts always contain two series overweight/underweight
    chartOptions.series = [
      {
        type: 'column',
        name: this.translateService.instant('overweight'),
        keys: ['name', 'y', 'selected', 'count', 'formattedY', 'dataKey'],
        data: [],
        states: {
          select: {
            borderColor: '#ffffff',
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, this.configService.getSuccessColor()],
                [0.25, this.configService.getSuccessColor()],
                [
                  1,
                  new Highcharts.Color(this.configService.getSuccessColor())
                    .brighten(0.2)
                    .get('rgb'),
                ],
              ],
            },
          },
        },
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, this.configService.getPrimaryColor()],
            [0.25, this.configService.getPrimaryColor()],
            [
              1,
              new Highcharts.Color(this.configService.getPrimaryColor())
                .brighten(0.2)
                .get('rgb'),
            ],
          ],
        },
        borderRadiusTopLeft: 3,
        borderRadiusTopRight: 3,
      } as Highcharts.SeriesColumnOptions,
      {
        type: 'column',
        name: this.translateService.instant('underweight'),
        keys: ['name', 'y', 'selected', 'count', 'formattedY', 'dataKey'],
        data: [],
        states: {
          select: {
            borderColor: '#ffffff',
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [
                  0,
                  new Highcharts.Color(this.configService.getSuccessColor())
                    .brighten(0.2)
                    .get('rgba'),
                ],
                [0.75, this.configService.getSuccessColor()],
                [1, this.configService.getSuccessColor()],
              ],
            },
          },
        },
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [
              0,
              new Highcharts.Color(this.configService.getDisableColor())
                .brighten(0.2)
                .get('rgb'),
            ],
            [0.75, this.configService.getDisableColor()],
            [1, this.configService.getDisableColor()],
          ],
        },
        borderRadiusBottomLeft: 3,
        borderRadiusBottomRight: 3,
      } as Highcharts.SeriesColumnOptions,
    ];

    chartOptions.lang = {
      loading: 'Loading ...',
      noData: 'No portfolios match the current filter criteria',
    };

    // Nodata module settings
    chartOptions.noData = {
      style: { fontSize: '16px', color: 'gray' },
      position: { align: 'left' },
    };

    return chartOptions;
  }

  /**
   * Function to generate default venn diagram chart options.
   * {@link https://api.highcharts.com/highcharts/}
   */
  private getDefaultVennDiagramChartOptions(): Highcharts.Options {
    const chartOptions: Highcharts.Options = {};

    chartOptions.chart = {
      type: 'venn',
    };

    chartOptions.title = {
      text: '',
    };

    chartOptions.tooltip = {
      enabled: false,
    };

    chartOptions.plotOptions = {};

    chartOptions.series = [
      {
        type: 'venn',
        name: 'Portfolio Universe',
        data: [],
      },
    ];

    chartOptions.lang = {
      loading: 'Loading ...',
      noData: 'No input data',
    };

    // Nodata module settings
    chartOptions.noData = {
      style: { fontSize: '16px', color: 'gray' },
      position: { align: 'left' },
    };

    return chartOptions;
  }

  private getDefaultLineChartOptions(): Highcharts.Options {
    const chartOptions: Highcharts.Options = {};

    chartOptions.chart = {
      type: 'line',
    };

    chartOptions.title = {
      text: '',
    };

    chartOptions.tooltip = {
      enabled: false,
    };

    chartOptions.xAxis = {
      categories: ['1', '2', '3', '4', '5', '6'],
    };

    chartOptions.plotOptions = {
      series: {
        marker: {
          enabled: false,
        },
      },
    };

    chartOptions.series = [
      /*{
        type: 'line',
        name: 'Stock A',
        data: [10, 20, 25, 23, 27, 30],
        color: this.configService.getAccentColor(),
      },
      {
        type: 'line',
        name: 'Stock B',
        data: [0, 5, 8, 6, 9, 15],
        color: this.configService.getHighlightColor(),
      },*/
    ];

    chartOptions.lang = {
      loading: 'Loading ...',
      noData: 'No input data',
    };

    // Nodata module settings
    chartOptions.noData = {
      style: { fontSize: '16px', color: 'gray' },
      position: { align: 'left' },
    };

    return chartOptions;
  }

  /**
   * Function to call filter based on selection in chart
   * @param event Select event on a chart
   * @param chartType Type of chart where select event originated
   */
  private onSelectFilterSubmit(event: any, chartType: EChartTypes): void {
    const isSimulator = location.pathname.endsWith(EViewRoutes.playground);
    if (
      !isSimulator &&
      !this.permissionService.hasAnyPermission(
        EProtectedActions.selectOnCampaignScatterChart,
        EProtectedActions.selectOnStoryScatterChart
      )
    ) {
      return;
    }
    this.currentFilter ||= this.filterService.getInitialFilterForm();
    this.updateFilterBasedOnEvent(this.currentFilter, event, chartType);
  }

  /**
   * Update filter values with event values and sends them back to backend
   * @param filter Currently active filter
   * @param event Select event on a chart
   * @param chartType Type of chart where select event originated
   */
  private updateFilterBasedOnEvent(
    filter: FilterConfig,
    event: any,
    chartType: EChartTypes
  ): void {
    // TODO: currently the con/disjunction is based on what is selected in the edit filter modal, w/o explanation to the user
    const overweight = this.translateService.instant('overweight');
    switch (chartType) {
      case EChartTypes.scatter:
        // set new risk/return values based on selected area
        // round values up to 2 digits after comma and transform to float value of percentage for backend
        filter.portfolio.returnRange = {
          max: Math.round(event.yAxis[0].max * 100) / 100,
          min: Math.round(event.yAxis[0].min * 100) / 100,
        };
        filter.portfolio.riskRange = {
          max: Math.round(event.xAxis[0].max * 100) / 100,
          min: Math.round(event.xAxis[0].min * 100) / 100,
        };
        break;
      case EChartTypes.assetClassBar:
        if (event.point.series.name === overweight) {
          this.updateCodeTableRangeFilter(
            filter.assetClass.children,
            event.point.dataKey,
            WeightRange.WeightEnum.Over
          );
        } else {
          this.updateCodeTableRangeFilter(
            filter.assetClass.children,
            event.point.dataKey,
            WeightRange.WeightEnum.Under
          );
        }
        break;
      case EChartTypes.currencyBar:
        if (event.point.series.name === overweight) {
          this.updateCodeTableRangeFilter(
            filter.currency.children,
            event.point.dataKey,
            WeightRange.WeightEnum.Over
          );
        } else {
          this.updateCodeTableRangeFilter(
            filter.currency.children,
            event.point.dataKey,
            WeightRange.WeightEnum.Under
          );
        }
        break;
      case EChartTypes.regionBar:
        if (event.point.series.name === overweight) {
          this.updateCodeTableRangeFilter(
            filter.region.children,
            event.point.dataKey,
            WeightRange.WeightEnum.Over
          );
        } else {
          this.updateCodeTableRangeFilter(
            filter.region.children,
            event.point.dataKey,
            WeightRange.WeightEnum.Under
          );
        }
        break;
      case EChartTypes.sectorBar:
        if (event.point.series.name === overweight) {
          this.updateCodeTableRangeFilter(
            filter.gics.children,
            event.point.dataKey,
            WeightRange.WeightEnum.Over
          );
        } else {
          this.updateCodeTableRangeFilter(
            filter.gics.children,
            event.point.dataKey,
            WeightRange.WeightEnum.Under
          );
        }
        break;
      default:
        break;
    }
    if (this.story || this.campaign) {
      this.filterService
        .upsertFilter(this.story, this.campaign, filter)
        .subscribe((newFilter) => this.dataService.updateFilter(newFilter));
    } else {
      // used for in-memory stats/simulator-playground
      this.dataService.updateFilter(filter);
    }
  }

  /**
   * Update code table range filters with weight
   * @param filter Code table range filter array
   * @param key Key of filter
   * @param weight Weight to set the filter
   */
  private updateCodeTableRangeFilter(
    filter: WeightRange[],
    key: string,
    weight: WeightRange.WeightEnum
  ): void {
    const filterItem: WeightRange = filter.find((f) => f.key === key);
    if (filterItem) {
      filterItem.weight = weight;
      // If weight is set, any exposure range on the same key should be removed
      filterItem.range = {
        max: null,
        min: null,
      };
    } else {
      filter.push({
        key,
        range: {
          max: null,
          min: null,
        },
        weight,
      });
    }
  }

  /**
   * Returns monitoring bar chart custom colors
   */
  private getMonitoringBarColors(): Highcharts.ColorType[] {
    return [
      {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, this.configService.getPrimaryColor()],
          [0.25, this.configService.getPrimaryColor()],
          [
            1,
            new Highcharts.Color(this.configService.getPrimaryColor())
              .brighten(0.2)
              .get('rgb'),
          ],
        ],
      } as Highcharts.GradientColorObject,
      {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, this.configService.getDisableColor()],
          [0.25, this.configService.getDisableColor()],
          [
            1,
            new Highcharts.Color(this.configService.getDisableColor())
              .brighten(0.2)
              .get('rgb'),
          ],
        ],
      } as Highcharts.GradientColorObject,
    ];
  }

  private relativeChartHeight(div: number = 2) {
    return (innerHeight - 250) / div;
  }
}
