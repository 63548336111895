<div class="catalogue-container">
  <app-card class="list-container">
    <div card-header>{{ 'monitoringRelationshipManager' | translate }}</div>
    <app-grid
      #grid
      tableId="monitoring-relationship-manager"
      [data]="data"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
      card-body
    ></app-grid>
  </app-card>
</div>
