import {Component, ViewChild} from "@angular/core";
import * as Highcharts from "highcharts";
import HighchartsMore from 'highcharts/highcharts-more';
import HighChartsNoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighChartsRoundedCorners from 'highcharts-rounded-corners';
import {HighchartsChartComponent} from "highcharts-angular";

import {MonitoringChartCount, MonitoringChartLines, MonitoringCharts, MonitoringChartValues} from "../../api/core";
import {ConfigService} from "../../services/config.service";
import {TranslateService} from "@ngx-translate/core";
import {HtmlService} from "../../services/html.service";
import {GlobalService} from "../../services/global.service";

HighChartsNoDataToDisplay(Highcharts);
HighchartsMore(Highcharts);
HighChartsRoundedCorners(Highcharts);
Highcharts.setOptions({
  credits: {
    enabled: false,
  },
});

export interface ChartOptions {
  width?: number;
  height?: number;
  titleAlign?: Highcharts.AlignValue;
  legendAlign?: Highcharts.AlignValue;
  legendVerticalAlign?: Highcharts.VerticalAlignValue;
  legendLayout?: Highcharts.OptionsLayoutValue;
  yAxisTitle?: string;
  tooltipFormatter?: (points: Highcharts.Point) => string;
}

@Component({
  selector: 'app-monitoring-chart',
  templateUrl: './monitoring-chart.component.html'
})
export class MonitoringChartComponent {
  @ViewChild('highcharts_chart') chart: HighchartsChartComponent;
  Highcharts = Highcharts;
  _chartOptions: Highcharts.Options;

  constructor(
    private readonly configService: ConfigService,
    private readonly translateService: TranslateService,
    private readonly htmlService: HtmlService,
    private readonly globalService: GlobalService,
  ) {
  }

  get chartOptions(): Highcharts.Options {
    return this._chartOptions;
  }

  set chartOptions(value: Highcharts.Options) {
    this._chartOptions = value;
    //console.debug('chartOptions', {w: value.chart.width, h: value.chart.width, series: value.series});
  }

  updateChartOptions(cb: (options: Highcharts.Options) => Highcharts.Options) {
    const newOptions = cb(this._chartOptions);
    if (newOptions !== this._chartOptions) {
      this.chartOptions = newOptions;
      const chart = (this.chart as any)?.chart;
      chart?.update(newOptions, true, true);
    }
  }

  getChartSize(options: ChartOptions) {
    return {
      width: options.width,
      height: options.height,
    };
  }

  resizeChart(options: ChartOptions) {
    this.updateChartOptions((src => ({
      ...src,
      chart: {
        ...src.chart,
        ...this.getChartSize(options)
      }
    })));
  }

  setPieData(title: string, pieData: MonitoringChartCount[], options: ChartOptions = {}) {
    options = {
      titleAlign: 'left',
      legendAlign: 'right',
      legendVerticalAlign: 'middle',
      legendLayout: 'vertical',
      ...options
    };
    if (this._chartOptions) {
      this.updateChartOptions(src => ({
        ...src,
        series: [{
          type: 'pie',
          data: pieData.map(p => ({
            name: p.name,
            y: p.count
          }))
        }]
      }));
      return;
    }
    this.chartOptions = {
      chart: {
        ...this.getChartSize(options),
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: title ? this.translateService.instant(title) : '',
        align: options.titleAlign,
      },
      legend: {
        align: options.legendAlign,
        alignColumns: true,
        verticalAlign: options.legendVerticalAlign,
        layout: options.legendLayout
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b> ({point.y:.0f})'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          colors: this.configService.getGeneralPieColors(),
          dataLabels: {
            enabled: false
          },
          showInLegend: true,
          size: '100%',
          innerSize: '50%',
        }
      },
      series: [{
        type: 'pie',
        data: pieData.map(p => ({
          name: p.name,
          y: p.count
        }))
      }]
    }

  }

  setTradeVolumeData(title: string, data: MonitoringCharts, options: ChartOptions = {}) {
    const allTrades = data.buyTrades.map((d, idx) => ({
      name: d.name,
      count: d.count + data.sellTrades[idx].count,
    }));
    const allVolumes = data.buyVolume.map((d, idx) => ({
      name: d.name,
      value: d.value + data.sellVolume[idx].value
    }));
    const nameTrades = this.translateService.instant('mChartTradesVolumeTrades');
    const nameVolumes = this.translateService.instant('mChartTradesVolumeVolume');
    if (this._chartOptions) {
      this.updateChartOptions(src => ({
        ...src,
        xAxis: {
          categories: data.buyTrades.map(d => d.name),
          crosshair: true
        },
        series: [
          {
            type: 'column',
            name: nameTrades,
            data: allTrades.map(d => d.count),
            yAxis: 0
          }, {
            type: 'line',
            name: nameVolumes,
            data: allVolumes.map(d => d.value),
            yAxis: 1
          }
        ]
      }));
      return;
    }
    this.chartOptions = {
      chart: {
        ...this.getChartSize(options),
        plotBorderWidth: 1,
        plotBorderColor: '#333333'
      },
      title: {
        text: title ? this.translateService.instant(title) : '',
        align: 'left'
      },
      xAxis: {
        categories: data.buyTrades.map(d => d.name),
        crosshair: true
      },
      yAxis: [
        {
          labels: {
            format: '{value:,.0f}',
            style: {
              color: Highcharts.getOptions().colors[0] as string
            }
          },
          title: {
            text: '',
            style: {
              color: Highcharts.getOptions().colors[0] as string,
            }
          },
        },
        {
          labels: {
            enabled: false
          },
          title: {
            text: ''
          }
        }
      ], // yAxis
      tooltip: {
        shared: true,
        formatter: function() {
          const points = this.points;
          return '<b>' + this.x + '</b>' +
            points.map(p =>
              `<br/> ${options.tooltipFormatter ? options.tooltipFormatter(p as unknown as Highcharts.Point) : `${p.series.name}: ${p.y}` }`
          ).join('');
        }
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom',
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || // theme
          'rgba(255,255,255,0.25)'
      },
      series: [
        {
          type: 'column',
          name: nameTrades,
          data: allTrades.map(d => d.count),
          yAxis: 0
        }, {
          type: 'line',
          name: nameVolumes,
          data: allVolumes.map(d => d.value),
          yAxis: 1
        }
      ]
    }; // chartOptions
  }

  setLineChart(slices: string[], data: MonitoringChartLines, name: string, options: ChartOptions = {}) {
    name = this.translateService.instant(`mChart${name}`);
    this.chartOptions = {
      chart: {
        ...this.getChartSize(options),
        margin: 0,
      },
      title: {
        text: '',
      },
      legend: {
        enabled: false
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '<b>{point.category}</b>: {point.y:,.0f}%'
      },
      xAxis: {
        visible: false,
        margin: 0,
        minPadding: 0,
        maxPadding: 0,
        categories: slices,
      },
      yAxis: {
        gridLineWidth: 0,
        visible: false,
        labels: {
          enabled: false
        },
        title: {
          text: ''
        },
        min: 0,
        max: 100,
      },
      series: [{
        name,
        type: 'area',
        data: data.values.map((d, k) => [slices[k], d * 100]),
        fillColor: 'rgba(200,200,200, 0.3)',
        marker: {
          enabled: false
        },
        lineWidth: 3,
        lineColor: 'rgb(200,200,200)',
        states: {
          hover: {
            lineWidth: 1
          }
        },
        threshold: null,
      }]
    };
  }

  updateLineChart(parent: HTMLElement) {
    const svg = parent.querySelector('svg');
    const area = this.htmlService.getElem('path.highcharts-area', svg) as SVGMPathElement;
    const line = this.htmlService.getElem('path.highcharts-graph', svg) as SVGMPathElement;
    if (!svg || !area || !line) return;
    const copyAttribute = (src: Element, dst: Element, name: string) => {
      const value = src.getAttribute(name);
      if (value) dst.setAttribute(name, value);
    }
    copyAttribute(line, area, 'stroke');
    copyAttribute(line, area, 'stroke-width');
    copyAttribute(line, area, 'stroke-linejoin');
    copyAttribute(line, area, 'stroke-linecap');
    //copyAttribute(line, area, 'filter');
  }

  setCentralizedCampaigns(categories: string[], data: MonitoringChartValues[], options: ChartOptions = {}) {
    if (this._chartOptions) {
      this.updateChartOptions(src => ({
        ...src,
        xAxis: {
          categories: [...categories]
        },
        series: data.map(d => ({
          type: 'column',
          name: d.name,
          data: d.values
        }))
      }));
      return;
    }
    this.chartOptions = {
      chart: {
        ...this.getChartSize(options),
        type: 'column',
        plotBorderWidth: 1,
        plotBorderColor: '#333333'
      },
      title: {
        text: '',
        align: 'left'
      },
      xAxis: {
        categories
      },
      yAxis: {
        min: 0,
        title: {
          text: ''
        },
        lineWidth: 1,
      },
      legend: {
        align: 'left',
        verticalAlign: 'bottom',
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>',
        shared: true
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        }
      },
      series: data.map(d => ({
        type: 'column',
        name: d.name,
        data: d.values,
      }))
    };
  }
  setLineCharts(title: string, data: MonitoringChartValues[], options: ChartOptions) {
    options = {
      titleAlign: 'center',
      legendAlign: 'right',
      legendVerticalAlign: 'middle',
      legendLayout: 'vertical',
      yAxisTitle: 'values',
      tooltipFormatter: p => `${p.series.name}: ${this.globalService.getFormattedValue(p.y, 2)}`,
      ...options
    };
    if (this._chartOptions) {
      this.updateChartOptions(src => ({
        ...src,
        series: data.map(d => ({
          type: 'line',
          name: d.name,
          data: d.values
        }))
      }));
      return;
    }
    this.chartOptions = {
      chart: {
        ...this.getChartSize(options),
        type: 'line',
      },
      title: {
        text: title,
        align: options.titleAlign
      },
      yAxis: {
        title: {
          text: options.yAxisTitle ? this.translateService.instant(options.yAxisTitle) : ''
        },
      },
      tooltip: {
        //pointFormat: '{series.name}: {point.y:,.2f}<br/>'
        formatter: function() {
          return options.tooltipFormatter(this.point);
        }
      },
      series: data.map(d => ({
        type: 'line',
        name: d.name,
        data: d.values
      })),
      plotOptions: {
        line: {
          tooltip: {
            headerFormat: '',
          }
        }
      }
    }
  }
}
