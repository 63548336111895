<div class="table-selection-bar">
  <ng-container *ngIf="isEditableCampaign">
    <mat-label for="selectAllCheckbox" class="checkbox-label" *ngIf="itemLabelRef"
      >{{ itemLabelRef | translate }}:&nbsp;
    </mat-label>
    <mat-checkbox
      class="select-all-checkbox"
      #selectAllCheckbox
      color="primary"
      [checked]="allSelected"
      [indeterminate]="someSelected"
      (click)="togglePageSelection($event)"
      [matTooltip]="getTooltip()"
    ></mat-checkbox>
  </ng-container>
  <app-grid-filter
    *ngIf="gridApi"
    [gridApi]="gridApi"
    [dialogTitle]="'filters' | translate"
    [filterModelSubject]="filterModelSubject"
    [showSearch]="true"
    (searchChanged)="searchChanged.emit($event)"
    [searchPlaceholder]="searchPlaceholder"
    [importantColumns]="importantColumns"
  ></app-grid-filter>
</div>
