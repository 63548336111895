import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {EChartTypes, EMonitoringTypes} from 'src/app/util/enum';
import {MonitoringCampaign, MonitoringChartCount, MonitoringService} from 'src/app/api/core';
import {MonitoringChartComponent} from "../monitoring-chart/monitoring-chart.component";
import {TranslateService} from "@ngx-translate/core";
import {HtmlService} from "../../services/html.service";
import {delay} from "rxjs";

interface MonitoringStatsSection {
  title: string;
  data: MonitoringChartCount[];
}

const offsetWidth = -10;
const offsetHeight = -10;

function getValue(name: string, count: number) {
  return {name, count};
}

@Component({
  selector: 'app-monitoring-charts-card',
  templateUrl: './monitoring-charts-card.component.html',
})
export class MonitoringChartsCardComponent implements OnInit, OnChanges {
  @ViewChild('chart_views') chartViews: MonitoringChartComponent;
  @ViewChild('chart_executions') chartExecutions: MonitoringChartComponent;
  @ViewChild('chart_channels') chartChannels: MonitoringChartComponent;
  @ViewChild('chart_languages') chartLanguages: MonitoringChartComponent;
  @ViewChild('chart_products') chartProducts: MonitoringChartComponent;
  @ViewChild('chart_channel_views') chartChannelViews: MonitoringChartComponent;
  @ViewChild('chart_document_views') chartDocumentViews: MonitoringChartComponent;
  @Input() monitoringType: EMonitoringTypes;
  @Input() monitoringCampaign: MonitoringCampaign;
  @Input() cached: boolean;

  initializedSwiper = false;
  updatedMaxSizes = false;

  constructor(
    protected readonly monitoringService: MonitoringService,
    protected readonly translateService: TranslateService,
    protected readonly htmlService: HtmlService,
  ) {
  }

  async ngOnInit() {
    await delay(0);
    // a delay is required since the component needs to be rendered first
    await this.initSwiper();
    await this.updateMaxSizes();
    this.htmlService.waitFor(
      () => !!this.monitoringCampaign && !!this.chartViews,
      () => this.updateStaticCharts(this.monitoringCampaign)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cached) {
      this.cached = changes.cached.currentValue;
      this.ngOnInit().then(_ => {
      });
    }
    if (changes.monitoringCampaign && !changes.monitoringCampaign.firstChange) {
      this.monitoringCampaign = changes.monitoringCampaign.currentValue;
      this.ngOnInit().then(_ => {
      });
    }
  }

  delay(ms: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    })
  }

  private async updateMaxSizes() {
    if (this.updatedMaxSizes) {
      return
    }
    this.updatedMaxSizes = true;
    const chartPages = this.htmlService.getElem('.chart-pages');
    const chartPageStyle = `${chartPages.clientWidth}px`;
    this.htmlService.getElems('.chart-page', chartPages)
      .forEach(el => {
        this.htmlService.addStyle(el, 'max-width', chartPageStyle)
      });
  }

  private async updateCharts() {
    const chartChannels = this.htmlService.getElem('.chart-graph.channels');
    const chartLanguages = this.htmlService.getElem('.chart-graph.languages');
    const chartProducts = this.htmlService.getElem('.chart-graph.products');
    const chartChannelViews = this.htmlService.getElem('.chart-graph.channel-views');
    const chartDocumentViews = this.htmlService.getElem('.chart-graph.document-views');
    const getTr = (key: string) => this.translateService.instant(key);
    this.monitoringService.getCampaignChart(this.monitoringCampaign.id, this.cached)
      .subscribe(chart => {
        this.chartChannels.setPieData(getTr('channel'), chart.channels, {
          titleAlign: 'center',
          legendLayout: 'horizontal',
          legendAlign: 'center',
          legendVerticalAlign: 'bottom',
          width: chartChannels.clientWidth + offsetWidth,
          height: chartChannels.clientHeight + offsetHeight
        });
        this.chartLanguages.setPieData(getTr('language'), chart.languages, {
          titleAlign: 'center',
          legendLayout: 'horizontal',
          legendAlign: 'center',
          legendVerticalAlign: 'bottom',
          width: chartLanguages.clientWidth + offsetWidth,
          height: chartLanguages.clientHeight + offsetHeight
        });
        this.chartChannelViews.setPieData(getTr("channelViews"), chart.channelViews, {
          titleAlign: 'center',
          legendLayout: 'vertical',
          legendAlign: 'left',
          legendVerticalAlign: 'middle',
          width: chartChannelViews.clientWidth + offsetWidth,
          height: chartChannelViews.clientHeight + offsetHeight
        });
        this.chartDocumentViews.setPieData(getTr("documentViews"), chart.documentViews, {
          titleAlign: 'center',
          legendLayout: 'vertical',
          legendAlign: 'right',
          legendVerticalAlign: 'middle',
          width: chartDocumentViews.clientWidth + offsetWidth,
          height: chartDocumentViews.clientHeight + offsetHeight
        });
        this.chartProducts.setLineCharts(getTr('portfolioMovements'), chart.productsPerformance, {
          width: chartProducts.clientWidth,
          height: chartProducts.clientHeight,
          yAxisTitle: undefined,
        });
        // this.updateChartSizes();
      });
  }

  private async updateStaticCharts(mc: MonitoringCampaign) {
    const getTr = (key: string) => this.translateService.instant(key);
    const chartViews = this.htmlService.getElem('.chart-graph.views');
    const chartExecutions = this.htmlService.getElem('.chart-graph.executions')

    this.chartViews.setPieData(getTr('viewed'), mc.clientContacts ? [
      getValue(getTr('viewed'), mc.clientViewed),
      getValue(getTr('notViewed'), mc.clientContacts - mc.clientViewed)
    ] : [], {
      titleAlign: 'center',
      legendLayout: 'horizontal',
      legendAlign: 'center',
      legendVerticalAlign: 'bottom',
      width: chartViews.clientWidth + offsetWidth,
      height: chartViews.clientHeight + offsetHeight
    });
    this.chartExecutions.setPieData(getTr('executedRate'), mc.clientContacts ? [
      getValue(getTr('executedRate'), mc.executions),
      getValue(getTr('notExecuted'), mc.clientContacts - mc.executions)
    ] : [], {
      titleAlign: 'center',
      legendLayout: 'horizontal',
      legendAlign: 'center',
      legendVerticalAlign: 'bottom',
      width: chartExecutions.clientWidth + offsetWidth,
      height: chartExecutions.clientHeight + offsetHeight
    });
    await this.updateCharts();
  }

  get chartTypes() {
    return EChartTypes;
  }

  private async initSwiper() {
    if (this.initializedSwiper) {
      return;
    }
    this.initializedSwiper = true;
    // swiper element
    const swiperEl: any = document.querySelector('swiper-container');
    if (swiperEl) {
      const swiperParams = {
        loop: true,
        pagination: {
          clickable: true,
        },
      };
      // assign all parameters to Swiper element
      Object.assign(swiperEl, swiperParams);
      // and now initialize it
      swiperEl.initialize();
    }
  }

}
