<div class="tabs-panel">
  <ng-container *ngFor="let tab of tabs">
    <app-tab
      *ngIf="hasPermission(tab)"
      [class.hidden]="tab.hidden"
      [icon]="tab.icon"
      [tabText]="tab.text"
      [suffix]="tab.suffix"
      [isActive]="tab.text === activeTab"
      (tabClicked)="onTabClicked($event)"
      [routerLink]="tab.routerLink"
    ></app-tab>
  </ng-container>
</div>
<div class="tab-actions">
  <ng-content select="[tab-action]"></ng-content>
</div>
