import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {AgPromise, GridApi, IFilter} from 'ag-grid-community';
import {finalize, first, Observable} from 'rxjs';
import {
  Campaign,
  CampaignActionService,
  CampaignIntermediaryActionService,
  CampaignPortfolio,
  CodeTableEntry,
  ContactChannel,
  Content,
  Portfolio,
  User,
  UserInfo,
  UserInteraction,
  UserService,
} from '../../../api/core';
import {ContentData} from '../../../models/content.model';
import {ModalData} from '../../../models/modal.model';
import {CustomPreviewService} from '../../../services/custom-preview.service';
import {DialogHeight, DialogWidth, ModalService} from '../../../services/modal.service';
import {NotificationService} from '../../../services/notification.service';
import {PermissionService} from '../../../services/permission.service';
import {ContentFormComponent} from '../../../shared/content-form/content-form.component';
import {ModalComponent} from '../../../shared/modal/modal.component';
import {PortfolioDetailsComponent} from '../../../shared/portfolio-details/portfolio-details.component';
import {IsCampaignEditablePipe} from '../../../shared/shared.pipe';
import {EModalType, EPortfolioActionStatus} from '../../../util/enum';
import {EProtectedActions} from '../../../util/protected-actions';
import {
  CampaignActionChannelSelectionComponent,
  ChannelAction
} from './campaign-action-channel-selection/campaign-action-channel-selection.component';
import {
  CampaignActionSenderSelectionComponent,
  SenderAction,
} from './campaign-action-sender-selection/campaign-action-sender-selection.component';
import {
  CampaignSuitabilityDetailsComponent
} from './campaign-suitability-details/campaign-suitability-details.component';
import {NgZone} from "@angular/core";

/**
 * Component that allows editing the sender of the actions:
 * - campaign-action
 * - campaign-intermediary-action
 *
 * Seen the interface SenderAction for more details, since that's
 * the data to be processed by this component.
 */
export enum ActionType {
  CampaignAction,
  IntermediateAction,
}

/**
 * Interface that declares required data to perform operations over actions with content
 */
export interface ContentAction {
    id: number;
    campaignId: number;
    type: ActionType;
    language: CodeTableEntry;
    content: Content;
    channel: ContactChannel;
}

/**
 * Common operations being used in the campaign action list and campaign portfolio list.
 */
export class CampaignActionListUtils {

    constructor(
      /**
       * NgZone is needed to avoid an error that appears when opening a modal, the dialog
       * is called, but no content is showed. The issue is related to this component not
       * being an Angular component, so the NgZone is needed to run the code inside the
       * Angular zone.
       * See:
       * - https://stackoverflow.com/questions/56470620/ngoninit-not-firing-when-opening-dialog
       * - https://github.com/angular/components/issues/24305
       */
        private zone: NgZone,
        private campaign: Campaign,
        private notificationService: NotificationService,
        private modalService: ModalService,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private isCampaignEditablePipe: IsCampaignEditablePipe,
        private customPreviewService: CustomPreviewService,
        private campaignActionService: CampaignActionService,
        private campaignIntermediaryActionService: CampaignIntermediaryActionService,
        private userService: UserService
    ) {
    }

  handleSenderCellClick(
    cellData: any,
    senderType: ActionType,
    refreshGrid: (d: any) => void
  ): void {
    if (!this.isSenderCellEditable(cellData)) {
      return;
    }
    const actionData: SenderAction = {
      type: senderType,
      sender: cellData.sender,
      id: cellData.id,
      portfolioIdent: cellData.portfolio?.ident,
    };
    const modalData: ModalData = {
      type: EModalType.editCampaignSender,
      title: EModalType.editCampaignSender,
      data: { action: actionData },
      submitBtn: {
        label: this.translateService.instant('update'),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: CampaignActionSenderSelectionComponent,
    };
    this.zone.run(() => {
      const dialogRef = this.modalService.openDefaultDialog(
        modalData,
        'campaign-action-sender-dialog',
        false,
        false,
        DialogWidth.HALF,
        DialogHeight.HALF
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          refreshGrid(result);
          this.notificationService.handleSuccess(
            this.translateService.instant('updateSenderSuccess')
          );
        }
      });
    });
  }

  isSenderCellEditable(data: any): boolean {
    const isAllowedAction = this.permissionService.hasAnyPermission(
      EProtectedActions.editCampaignSender
    );
    const isPending = data.status === EPortfolioActionStatus.pending;
    return (
      isAllowedAction &&
      this.isCampaignEditablePipe.transform(this.campaign.status) &&
      isPending
    );
  }

  isChannelCellEditable(data: any): boolean {
    return (
      this.permissionService.hasAnyPermission(
        EProtectedActions.editCampaignChannel
      ) &&
      this.permissionService.hasPermissionForCampaignOverview(this.campaign) &&
      data.status === EPortfolioActionStatus.pending &&
      !data.content
    );
  }

  handleChannelCellClick(cellData: any, type: ActionType, refreshGrid: (data: any) => void, viewReadonly: boolean = false): void {
    const isEditable = this.isChannelCellEditable(cellData);
    if (!isEditable && !viewReadonly) {
      return;
    }

    const actionData: ChannelAction = {
      id: cellData.id,
      type,
      portfolioIdent: cellData.portfolio?.ident,
      clientIdent: cellData.client?.ident,
      channel: cellData.channel,
    }

    const modalData: ModalData = {
      type: EModalType.editCampaignChannel,
      title: isEditable ? EModalType.editCampaignChannel : EModalType.viewCampaignChannel,
      data: {action: actionData, readonly: !isEditable},
      submitBtn: isEditable ? {
        label: this.translateService.instant('update'),
      } : undefined,
      cancelBtn: {
        label: this.translateService.instant(isEditable ? 'cancel' : 'close'),
      },
      component: CampaignActionChannelSelectionComponent,
    };
    this.zone.run(() => {
      const dialogRef = this.modalService.openDefaultDialog(
        modalData,
        null,
        false,
        false,
        DialogWidth.HALF,
        DialogHeight.HALF
      );

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          refreshGrid(result);
          this.notificationService.handleSuccess(
            this.translateService.instant('updateChannelSuccess')
          );
          this.userService.recordUserInteraction(
            actionData.type === ActionType.CampaignAction ?
              UserInteraction.CHANGEDCAMPAIGNACTIONCHANNEL : UserInteraction.CHANGEDCAMPAIGNINTERMEDIARYACTIONCHANNEL
          ).pipe(first()).subscribe();
        }
      });
    });
  }

  handleCustomContentAction(
    data: ContentAction,
    action: string,
    isIntermediary: boolean,
    refreshGrid: (d: any) => void
  ): void {
    switch (action) {
      case 'add':
        this.addCustomContent(data, refreshGrid);
        break;
      case 'edit':
        this.editCustomContent(data, refreshGrid);
        break;
      case 'delete':
        this.deleteCustomContent(data, refreshGrid);
        break;
      case 'preview':
        this.previewCustomContent(data, isIntermediary);
        break;
      default:
        break;
    }
  }

  private newContent(params: {
    editable: boolean;
    campaignId: number;
    channel: ContactChannel;
    language: CodeTableEntry;
    id: number;
    type: ActionType;
    content: Content
  }) {
    return new ContentData(
      params.type,
      params.language,
      params.id,
      params.content,
      true,
      params.campaignId,
      params.channel,
      params.content.contentDefinitionId
    );
  }

    private addCustomContent(
        data: ContentAction,
        refreshGrid: (d: any) => void
    ): void {
        let serviceCall: Observable<Content>;
        if (data.type === ActionType.CampaignAction) {
            serviceCall = this.campaignActionService
                .createEphemeralCampaignActionContent(data.id);
        } else {
            serviceCall = this.campaignIntermediaryActionService.createEphemeralContent(data.id);
        }

      serviceCall.subscribe((content) => {
            this.showCustomContentDialog(
                this.newContent({
                  type: data.type,
                  language: content.language,
                  id: data.id,
                  content: content,
                  editable: true,
                  campaignId: data.campaignId,
                  channel: data.channel,
                }),
                EModalType.createCustomContent,
                refreshGrid
            );
        });
    }

    private editCustomContent(data: ContentAction, refreshGrid: (d: any) => void) {
      this.showCustomContentDialog(
          this.newContent({
            type: data.type,
            language: data.language,
            id: data.id,
            content: data.content,
            editable: true,
            campaignId: data.campaignId,
            channel: data.channel,
          }),
          EModalType.updateCustomContent,
          refreshGrid
      );
    }

  private showCustomContentDialog(
    contentData: ContentData,
    modalType: EModalType,
    refreshGrid: (d: any) => void
  ): void {
    const modalData: ModalData = {
      type: modalType,
      title: modalType,
      data: contentData,
      component: ContentFormComponent,
    };
    this.zone.run(() => {
      const dialogRef = this.modalService.openDefaultDialog(modalData, 'custom-content-padding');

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          refreshGrid(result);
          this.notificationService.handleSuccess(
            this.translateService.instant('contentUpdateSuccess')
          );
          if (modalType === EModalType.createCustomContent) {
            this.userService.recordUserInteraction(
              contentData.type === ActionType.CampaignAction ?
                UserInteraction.ADDEDCAMPAIGNACTIONCONTENT : UserInteraction.ADDEDCAMPAIGNINTERMEDIARYACTIONCONTENT
            ).pipe(first()).subscribe();
          }
        }
      });
    });
  }

    private deleteCustomContent(action: ContentAction, refreshGrid: (d: any) => void): void {
        let serviceCall: Observable<any>;
        if (action.type === ActionType.CampaignAction) {
            serviceCall = this.campaignActionService.deleteCampaignActionContent(action.id);
        } else {
            serviceCall = this.campaignIntermediaryActionService.deleteContent(action.id);
        }
        const modalData: ModalData = {
            title: this.translateService.instant('deleteCustomContent?'),
            type: EModalType.confirmationDialog,
            data: {
                message: this.translateService.instant('deleteCustomContentMessage'),
            },
            component: null,
            submitBtn: {
                label: this.translateService.instant('delete'),
                callback: (modalRef: MatDialogRef<ModalComponent>) => {
                    serviceCall
                        .pipe(
                            first(),
                            finalize(() =>
                                modalRef.componentInstance.resetToolbarActionButtons()
                            )
                        )
                        .subscribe({
                            next: (data) => {
                                modalRef.close(true);
                                refreshGrid(data);
                                this.notificationService.handleSuccess(
                                    this.translateService.instant('deleteCustomContentSuccess')
                                );
                            },
                        });
                },
            },
            cancelBtn: {
                label: this.translateService.instant('cancel'),
            },
        };
    this.zone.run(() => {
      this.modalService.openConfirmationDialog(modalData);
    });
  }

  private previewCustomContent(data: any, isIntermediary: boolean) {
      this.customPreviewService.previewCustomContent(data, isIntermediary);
  }

  openPortfolioDetailsModal(data: any): void {
    const portfolioData = this.convertCampaignPortfolioToPortfolio(data);
    const modalData: ModalData = {
      type: EModalType.detailsDialog,
      title: this.translateService.instant('portfolioDetails'),
      data: portfolioData,
      component: PortfolioDetailsComponent,
    };
    this.zone.run(() => {
      this.modalService.openDefaultDialog(modalData, undefined, false, false, undefined, DialogHeight.AUTO);
    });
  }

  private convertCampaignPortfolioToPortfolio(data: any): Portfolio {
    if (data.portfolioIdent) {
      const d = data as CampaignPortfolio;
      return {
        id: d.portfolioId,
        ident: d.portfolioIdent,
        number: d.portfolioNumber,
        bpNumber: d.portfolioBpNumber,
        bpName: d.portfolioBpName,
        portfolioType: d.portfolioType,
        strategy: d.portfolioStrategy,
        referenceCurrency: d.portfolioCurrency,
        portfolioValue: d.portfolioValue,
        risk: d.portfolioRisk,
        riskSpreadMin: d.portfolioRiskSpreadMin,
        riskSpreadMax: d.portfolioRiskSpreadMax,
        relationshipManager: d.portfolioRelationshipManager,
        advisor: d.portfolioAdvisor,
        advisoryType: d.portfolioAdvisoryType,
        closed: d.portfolioClosed,
        riskState: d.portfolioRiskState,
        preferredLanguage: d.portfolioPreferredLanguage,
      } as Portfolio;
    } else {
      return data;
    }
  }

  isDetailLangCellEditable(params: any): boolean {
    const hasContents = this.campaign.contents?.length > 0;
    return (
      hasContents &&
      this.permissionService.hasAnyPermission(
        EProtectedActions.editActionLanguage
      ) &&
      !params.data.content &&
      this.permissionService.hasPermissionForCampaignOverview(this.campaign) &&
      params.data.status === EPortfolioActionStatus.pending
    );
  }

  getLanguageEditParams(_): any {
    const contentLanguages = this.campaign.contents.map((t) => t.language);
    const entries = [
      ...new Map(contentLanguages.map((item) => [item.id, item])).values()
    ];
    return {
      values: entries.map((language) => language.name),
      valueListGap: 3,
    };
  }

  openClientSuitability(data: any): void {
    const modalData: ModalData = {
      type: EModalType.suitabilityDetails,
      title: EModalType.suitabilityDetails,
      data: {
        state: data.state,
        campaignActionId: data.campaignActionId,
      },
      component: CampaignSuitabilityDetailsComponent,
    };
    this.zone.run(() => {
      this.modalService.openDefaultDialog(
        modalData,
        null,
        false,
        false,
        DialogWidth.DEFAULT,
        DialogHeight.DEFAULT
      );
    });
  }

  setAssigneeFilter(
    gridApi: GridApi,
    currentUsernames: string[],
    filterKey: string,
    assignees: UserInfo[],
    selectedAssignees: string[]
  ) : AgPromise<IFilter> {
      const p = gridApi?.getColumnFilterInstance(filterKey) as unknown as AgPromise<IFilter>;
      if (!p) return AgPromise.resolve(undefined);
      return p.then(assigneeFilter => {

        const canExecute = this.permissionService.hasAnyPermission(
          EProtectedActions.executeActionCampaign
        );

        const query = new URLSearchParams(location.search);
        /**
         * User set assignee filter gets reset, when change dynamic users selected
         * Could be fixed by using assigneeFilter.getModel() and check which values are not affected by
         * dynamic user change
         */
        if (canExecute) {
          // selected assignees used to keep filter state when changing from list to hierarchy view (and vice versa)
          let values = selectedAssignees;
          if (values.length === 0 && query.has('campaign-portfolio-listState')) {
            const states = JSON.parse(localStorage.getItem('tableStates')) || {};
            const state = states[query.get('campaign-portfolio-listState')];
            if (state.filterModel && state.filterModel['assignee.username']) {
              const assignees = state.filterModel['assignee.username'];
              values = assignees?.values || [];
            }
            if (
              state.filterModel &&
              state.filterModel['campaignActions.assignee.username']
            ) {
              const assignees =
                state.filterModel['campaignActions.assignee.username'];
              values = assignees?.values || [];
            }
          }

          const assigneeUsernames = assignees.map((u) => u.username);
          if (
            values.length === 0 &&
            assigneeUsernames.some((username) =>
              currentUsernames.includes(username)
            )
          ) {
            values = [...currentUsernames].filter((username) =>
              assigneeUsernames.includes(username)
            );
          }

          (assigneeFilter as any).getValueModel().refreshValues();
          if (values.length > 0) {
            return (assigneeFilter.setModel({ values }) as AgPromise<void>)
              .then(() => {
                gridApi.onFilterChanged();
                return assigneeFilter;
              });
          }
        }
      }) as unknown as AgPromise<IFilter>;
  }

  distinctUsers(users: User[]): User[] {
    const distinct: Record<string, User> = {};
    users.forEach((u) => (distinct[u.username] = u));
    return Object.values(distinct);
  }
}
