<div class="chart-pages">
  <swiper-container init="false">
    <swiper-slide>
      <div class="chart-page">
        <div class="chart-graph views">
          <app-monitoring-chart #chart_views></app-monitoring-chart>
        </div>
        <div class="chart-graph executions">
          <app-monitoring-chart #chart_executions></app-monitoring-chart>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="chart-page">
        <div class="chart-graph channels">
          <app-monitoring-chart #chart_channels></app-monitoring-chart>
        </div>
        <div class="chart-graph languages">
          <app-monitoring-chart #chart_languages></app-monitoring-chart>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="chart-page">
        <div class="chart-graph channel-views">
          <app-monitoring-chart #chart_channel_views></app-monitoring-chart>
        </div>
        <div class="chart-graph document-views">
          <app-monitoring-chart #chart_document_views></app-monitoring-chart>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="chart-page">
        <div class="chart-graph products">
          <app-monitoring-chart #chart_products></app-monitoring-chart>
        </div>
        <div class="chart-graph other">
        </div>
      </div>
    </swiper-slide>
  </swiper-container>

</div>
