import {Injectable} from "@angular/core";

export type Predicate = () => boolean;
export type Action = () => void;

export interface ElemSize {
  width: number;
  height: number;
}

@Injectable({ providedIn: 'root' })
export class HtmlService {
  getElem(selector: string, parent: ParentNode = document): Element | undefined {
    return parent.querySelector(selector);
  }
  getElems(selector: string, parent: ParentNode = document): Element[] {
    return Array.from(parent.querySelectorAll(selector));
  }
  getWidth(selectorParent: string, ...selectorChildren: string[]): number {
    const parent = selectorParent ? this.getElem(selectorParent) : undefined;
    const clientWidth = parent ? parent.clientWidth : window.innerWidth;
    return selectorChildren
      .map(s => this.getElem(s))
      .filter(Boolean)
      .map(el => el.clientWidth)
      .reduce((prev, curr) => prev - curr , clientWidth)
  }
  getHeight(selectorParent: string, ...selectorChildren: string[]): number {
    const parent = selectorParent ? this.getElem(selectorParent) : undefined;
    const clientHeight = parent ? parent.clientHeight : window.innerHeight;
    return selectorChildren
      .map(s => this.getElem(s))
      .filter(Boolean)
      .map(el => el.clientHeight)
      .reduce((prev, curr) => prev - curr , clientHeight)
  }
  getElemSize(el: Element): ElemSize {
    return {
      width: el.clientWidth,
      height: el.clientHeight,
    };
  }
  addStyle(el: Element, name: string, value: string | number) {
    const style = el.getAttribute("style") || '';
    const styleObj = this.getStyleMap(style);
    if (!styleObj[name]) {
      styleObj[name] = value.toString();
      el.setAttribute('style', this.getStyleString(styleObj));
    }
  }

  getStyleMap(src: string): Record<string, string> {
    const result: Record<string, string> = {};
    src.split(';')
      .filter(Boolean)
      .forEach(value => {
      const parts = value.split(':');
      if (parts.length == 2) {
        result[parts[0].trim()] = parts[1].trim();
      }
    })
    return result;
  }

  getStyleString(src: Record<string, string>): string {
    return Object.entries(src)
      .map(([key, val]) => `${key}: ${val};`)
      .join('');
  }
  getValue(value: number, unit: string = "px"): string {
    return [value, unit].join('');
  }
  waitFor(cond: Predicate, action: Action, interval: number = 100) {
    if (cond()) {
      action();
      return;
    }
    setTimeout(() => this.waitFor(cond, action, interval), interval);
  }
  rem2pixel(rem: number): number {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }
}
