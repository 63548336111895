import * as Highcharts from 'highcharts';
import HighChartsNoDataToDisplay from "highcharts/modules/no-data-to-display";
import HighchartsMore from "highcharts/highcharts-more";
import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {ChartService} from 'src/app/services/chart.service';
import {DataService} from 'src/app/services/data.service';
import {Subscription} from 'rxjs';
import {EChartTypes, ECodeTables} from 'src/app/util/enum';
import {IPieChartDataPoint, IScatterChartDataPoint,} from 'src/app/models/chart.model';
import {ConfigService} from 'src/app/services/config.service';
import {
  BarData,
  Campaign,
  CampaignActionStatus, CampaignStatus,
  CodeTableEntry,
  PieDataItem,
  ScatterDataItem,
  Story,
} from 'src/app/api/core';
import {NotificationService} from 'src/app/services/notification.service';
import {CustomData} from 'src/app/models/custom_data.model';
import {TranslateService} from '@ngx-translate/core';
import {environment} from 'src/environments/environment';
import {CodeTableService} from 'src/app/services/code-table.service';

HighChartsNoDataToDisplay(Highcharts);
HighchartsMore(Highcharts);
Highcharts.setOptions({
  credits: {
    enabled: false,
  },
});

/**
 * Chart Component.
 * Component for all types of chart. Can handle any type of Highcharts chart
 */
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
})
export class ChartComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * Component input to set chart type
   */
  @Input() chartType: EChartTypes;
  @Input() campaign: Campaign;
  @Input() story: Story;

  /**
   * Component input to set chart data (only used for product charts)
   */
  @Input()
  set data(data: any) {
    // when this function is executed the first time chartOptions need to be initialized
    if (!this.chartOptions) {
      this.initChart();
    }
    let pieOptions: Highcharts.SeriesPieOptions;
    switch (this.chartType) {
      case EChartTypes.prodAssetClassPie:
        pieOptions = this.chartOptions.series[0] as Highcharts.SeriesPieOptions;
        pieOptions.data = data.prodAssetClassPieData.map(
          (point: { assetClass: CodeTableEntry; weight: number }) => [
            point.assetClass.name,
            point.weight,
          ]
        );
        pieOptions.showInLegend = true;
        this.setProductPieOptions();
        break;
      case EChartTypes.prodRegionPie:
        pieOptions = this.chartOptions.series[0] as Highcharts.SeriesPieOptions;
        pieOptions.data = data.prodRegionPieData.map(
          (point: { region: CodeTableEntry; weight: number }) => [
            point.region.name,
            point.weight,
          ]
        );
        pieOptions.showInLegend = true;
        this.setProductPieOptions();
        break;
      case EChartTypes.prodSectorPie:
        pieOptions = this.chartOptions.series[0] as Highcharts.SeriesPieOptions;
        pieOptions.data = data.prodSectorPieData.map(
          (point: { gics: CodeTableEntry; weight: number }) => [
            point.gics.name,
            point.weight,
          ]
        );
        pieOptions.showInLegend = true;
        this.setProductPieOptions();
        break;
      case EChartTypes.monitoringContactedPie:
      case EChartTypes.monitoringExecutedPie:
      case EChartTypes.monitoringContacted3Pie:
      case EChartTypes.monitoringPortfolioRatePie:
      case EChartTypes.monitoringExecutedPiePerSentAction:
        pieOptions = this.chartOptions.series[0] as Highcharts.SeriesPieOptions;
        pieOptions.data = data.map((point: { name: string; count: number }) => [
          this.translateService.instant(point.name),
          point.count,
        ]);
        pieOptions.showInLegend = true;
        break;
      case EChartTypes.monitoringPie:
        const values = data;
        pieOptions = this.chartOptions.series[0] as Highcharts.SeriesPieOptions;
        pieOptions.data = values.map(
          (point: { name: string; count: number }) => [
            this.translateService.instant(point.name),
            point.count,
          ]
        );
        pieOptions.showInLegend = true;
        this.chartOptions.title.text = data.title ? this.translateService.instant(data.title) : '';
        this.chartOptions.title.verticalAlign = 'top';
        break;
      case EChartTypes.monitoringTradingVolumeBar:
      case EChartTypes.monitoringEarningsBar:
        const barOptions: Highcharts.SeriesColumnOptions = this.chartOptions
          .series[0] as Highcharts.SeriesColumnOptions;
        this.chartOptions.xAxis = [
          {
            categories: data.categories.map((cat: string) =>
              this.translateService.instant(cat)
            ),
          },
        ];
        barOptions.data = data.data.map((d: number) => Math.round(d));
        break;
      case EChartTypes.monitoringHoldingLineChart:
        this.chartOptions.lang.noData = 'No data to show.';
        this.chartOptions.title.text = this.translateService.instant('portfolioMovements');
        this.chartOptions.series = (data as Array<any>).map((d) => ({
          type: 'line',
          name: d.name,
          data: d.values,
        }));
        if (this.chartOptions.series.length === 0) {
          this.chartOptions.series = [
            {
              type: 'line',
              name: this.translateService.instant('portfolioMovements'),
              data: [],
            },
          ];
        }
        break;
      case EChartTypes.actionChannelPie:
      case EChartTypes.actionLanguagePie:
        const pieData = data as PieDataItem[];
        this.chartOptions.title.text = this.translateService.instant(
          this.chartType === EChartTypes.actionChannelPie
            ? 'actionChannel'
            : 'actionLanguage'
        );
        this.chartOptions.series = [
          {
            type: 'pie',
            showInLegend: true,
            data: pieData.map((d) => ({
              name: `${d.key} (${d.value})`,
              y: d.value,
            })),
          },
        ];
        this.chartOptions.legend = {
          enabled: false,
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical',
        };
        break;
      case EChartTypes.homeParticipatedPie:
        pieOptions = this.chartOptions.series[0] as Highcharts.SeriesPieOptions;
        pieOptions.data = data?.data.map(
          (point: { name: string; value: number }) => [
            this.translateService.instant(point.name),
            point.value,
          ]
        );
        this.chartOptions.title.text = data?.title ? this.translateService.instant(data.title) : '';
        this.chartOptions.title.verticalAlign = 'bottom'; // makes title appear bellow graph
        this.chartOptions.title.style = { whiteSpace: 'nowrap'};
        // number in the middle of the chart
        this.chartOptions.subtitle = {
          text: data?.subtitle?.toString() ? data.subtitle : '',
          floating: true,
          verticalAlign: 'middle',
          y: 25, // needs to be adjusted if font size changes -> centers the number
          style: { fontSize: '3rem', fontWeight: 'bold'},
        }
        this.chartOptions.legend = { enabled: false };
        this.chartOptions.lang.noData = 'No data to show.';
        this.chartOptions.chart.height = (innerHeight - 80) / 4; // 1/4 of the available height (minus 80px for the header)
        break;
      default:
        break;
    }
    // tell chart to update and is done loading
    this.updateFlag = true;
    this.loading = false;
  }

  /**
   * Clicked area in venn chart.
   */
  @Output() clickedVennElement?: EventEmitter<number> =
    new EventEmitter<number>();

  /**
   * Reference to Highcharts library
   */
  Highcharts: typeof Highcharts = Highcharts;
  /**
   * Optional chart constructor, defaults to 'chart'
   */
  chartConstructor = 'chart';
  /**
   * Highcharts options to render chart
   */
  chartOptions: Highcharts.Options;
  /**
   * Optional callback function to fire after chart initalization, defaults to null.
   * Used to bind chart object
   */
  chartCallback: Highcharts.ChartCallbackFunction = this.callback.bind(this);
  /**
   * Optional flag to tell the chart if an update was done
   */
  updateFlag = false;
  /**
   * Optional flag to ?, defaults to false
   */
  oneToOneFlag = true;
  /**
   * Optional flag to ?, defaults to false
   */
  runOutsideAngular = false; // optional boolean, defaults to false
  /**
   * Reference to chart object
   */
  chartObject: any;
  /**
   * Legend options for specific pie charts
   */
  productPiesLegendOptions: Highcharts.LegendOptions = {
    y: 300,
    floating: true,
    itemMarginTop: 0,
    verticalAlign: 'top',
  };
  /**
   * Subscription to chart data source. Keep reference to be able to unsubscribe
   */
  private dataSubscription: Subscription;
  /**
   * Flag to show spinner while loading
   */
  loading = true;
  /**
   * Flag to check if ngAfterViewInit was executed
   */
  afterViewInit = false;

  assetClasses: CodeTableEntry[];
  currencies: CodeTableEntry[];
  gics: CodeTableEntry[];
  regions: CodeTableEntry[];
  genders: CodeTableEntry[];
  countries: CodeTableEntry[];
  portfolioStrategies: CodeTableEntry[];
  codeTableLoaded = false;
  currentData: CustomData;
  kpiPieLegends: Highcharts.LegendOptions = {
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    x: 0,
    y: 100,
  };

  constructor(
    protected configService: ConfigService,
    protected chartService: ChartService,
    protected dataService: DataService,
    protected codeTableService: CodeTableService,
    protected notificationService: NotificationService,
    protected translateService: TranslateService
  ) {}

  ngOnInit(): void {
    // no need to init product charts since they do it in their data set
    if (!this.isNonSimulatorDataChart()) {
      this.initChart();
      this.loadRequiredCodeTable();
      this.dataSubscription = this.dataService.data$.subscribe((data) => {
        this.updateChartSeries(data);
      });
    }
    this.chartOptions.noData.position.align = 'center';
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.afterViewInit = true), 0);
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  get chartTypes() {
    return EChartTypes;
  }

  /**
   * Checks if chart is a product chart
   */
  private isNonSimulatorDataChart(): boolean {
    return (
      this.chartType === EChartTypes.prodAssetClassPie ||
      this.chartType === EChartTypes.prodRegionPie ||
      this.chartType === EChartTypes.prodSectorPie ||
      this.chartType === EChartTypes.monitoringContactedPie ||
      this.chartType === EChartTypes.monitoringContacted3Pie ||
      this.chartType === EChartTypes.monitoringExecutedPie ||
      this.chartType === EChartTypes.monitoringExecutedPiePerSentAction ||
      this.chartType === EChartTypes.monitoringPortfolioRatePie ||
      this.chartType === EChartTypes.monitoringTradingVolumeBar ||
      this.chartType === EChartTypes.monitoringEarningsBar ||
      this.chartType === EChartTypes.actionChannelPie ||
      this.chartType === EChartTypes.actionLanguagePie ||
      this.chartType === EChartTypes.monitoringPie ||
      this.chartType === EChartTypes.homeParticipatedPie
    );
  }

  /**
   * Initializes chart options with default chart options
   */
  private initChart(): void {
    if (
      this.chartOptions?.series?.length &&
      this.chartType === EChartTypes.monitoringHoldingLineChart
    )
      return;
    this.chartOptions = this.chartService
      .init(this.story, this.campaign)
      .getChartOptions(this.chartType);
  }

  private loadRequiredCodeTable(): void {
    switch (this.chartType) {
      case EChartTypes.scatter:
        this.codeTableService
          .getCodeTable(ECodeTables.portfolioStrategy)
          .subscribe((data) => {
            this.portfolioStrategies = data;
            this.codeTableLoaded = true;
            this.codeTableLoadedDone();
          });
        break;
      case EChartTypes.assetClassBar:
      case EChartTypes.assetClassPie:
        this.codeTableService
          .getCodeTable(ECodeTables.assetClass)
          .subscribe((data) => {
            this.assetClasses = data;
            this.codeTableLoaded = true;
            this.codeTableLoadedDone();
          });
        break;
      case EChartTypes.currencyBar:
      case EChartTypes.currencyPie:
        this.codeTableService
          .getCodeTable(ECodeTables.currency)
          .subscribe((data) => {
            this.currencies = data;
            this.codeTableLoaded = true;
            this.codeTableLoadedDone();
          });
        break;
      case EChartTypes.regionBar:
      case EChartTypes.regionPie:
        this.codeTableService
          .getCodeTable(ECodeTables.region)
          .subscribe((data) => {
            this.regions = data;
            this.codeTableLoadedDone();
          });
        break;
      case EChartTypes.sectorBar:
      case EChartTypes.sectorPie:
        this.codeTableService
          .getCodeTable(ECodeTables.gics)
          .subscribe((data) => {
            this.gics = data;
            this.codeTableLoadedDone();
          });
        break;
      default:
        this.codeTableLoaded = true;
        break;
    }
  }

  private codeTableLoadedDone(): void {
    this.codeTableLoaded = true;
    if (this.currentData) {
      this.updateChartSeries(this.currentData);
    }
  }

  /**
   * Callback function to bind chart object to rendered chart
   */
  private callback(chart: any) {
    this.chartObject = chart;
  }

  /**
   * Update chart series of chart component based on incoming chart data
   * @param data Provided chart data
   */
  private updateChartSeries(data: CustomData) {
    this.currentData = data;
    if (this.codeTableLoaded && data && Object.keys(data).length) {
      switch (this.chartType) {
        case EChartTypes.scatter:
          if (data.scatter && data.scatter.length) {
            const scatterChartOptions = this.chartOptions
              .series[0] as Highcharts.SeriesScatterOptions;
            scatterChartOptions.data = data.scatter.map(
              (point: IScatterChartDataPoint & ScatterDataItem) => [
                null,
                point.risk,
                point.return,
                'description', // TODO currently not used, refactoring needed
              ]
            );
            // remove min/max and show axis
            this.chartOptions.xAxis[0] = {
              ...this.chartOptions.xAxis[0],
              min: undefined,
              max: undefined,
              visible: true,
            };
            this.chartOptions.yAxis[0] = {
              ...this.chartOptions.yAxis[0],
              min: undefined,
              max: undefined,
              visible: true,
            };
            // mpf/strategies
            if (environment.OWNER !== 'LGT') {
              const mpfChartOptions = this.chartOptions
                .series[1] as Highcharts.SeriesScatterOptions;
              mpfChartOptions.data = this.portfolioStrategies.map(
                (strategy: CodeTableEntry) => [
                  strategy.name,
                  parseFloat(strategy.additionalData['risk']),
                  parseFloat(strategy.additionalData['ret']),
                ]
              );
            }
          } else {
            const scatterChartOptions = this.chartOptions
              .series[0] as Highcharts.SeriesScatterOptions;
            scatterChartOptions.data = [];
            // set min/max and hide axis to prevent highcharts error
            this.chartOptions.xAxis[0] = {
              ...this.chartOptions.xAxis[0],
              min: 0,
              max: 0,
              visible: false,
            };
            this.chartOptions.yAxis[0] = {
              ...this.chartOptions.yAxis[0],
              min: 0,
              max: 0,
              visible: false,
            };
          }
          // tell chart to update and is done loading
          this.updateFlag = true;
          this.loading = false;
          break;
        case EChartTypes.assetClassBar:
          // set categories
          this.chartOptions.xAxis = {
            categories: (data.bar?.assetClass?.categories || []).map((s) =>
              this.getLabelOfEntry('assetClass', s)
            ),
          };
          this.handleBarData(data.bar.assetClass);
          // tell chart to update and is done loading
          this.updateFlag = true;
          this.loading = false;
          break;
        case EChartTypes.currencyBar:
          // set categories
          this.chartOptions.xAxis = {
            categories: data.bar?.currency?.categories || [],
          };

          this.handleBarData(data.bar.currency);
          // tell chart to update and is done loading
          this.updateFlag = true;
          this.loading = false;
          break;
        case EChartTypes.regionBar:
          // set categories
          this.chartOptions.xAxis = {
            categories: (data.bar?.region?.categories || []).map((s) =>
              this.getLabelOfEntry('region', s)
            ),
          };

          this.handleBarData(data.bar.region);
          // tell chart to update and is done loading
          this.updateFlag = true;
          this.loading = false;
          break;
        case EChartTypes.sectorBar:
          // set categories
          this.chartOptions.xAxis = {
            categories: (data.bar?.gics?.categories || []).map((s) =>
              this.getLabelOfEntry('gics', s)
            ),
          };
          this.handleBarData(data.bar.gics);
          // tell chart to update and is done loading
          this.updateFlag = true;
          this.loading = false;
          break;
        case EChartTypes.portfolioSuitabilityPie:
          this.handlePieData(
            data.statistics.pie.portfolioSuitabilities,
            'portfolioSuitabilities'
          );
          this.chartOptions.legend = this.kpiPieLegends;
          // tell chart to update and is done loading
          this.updateFlag = true;
          this.loading = false;
          break;
        case EChartTypes.actionSuitabilityPie:
          this.handlePieData(
            data.statistics.pie.actionSuitabilities,
            'actionSuitabilities'
          );
          this.chartOptions.legend = this.kpiPieLegends;
          // tell chart to update and is done loading
          this.updateFlag = true;
          this.loading = false;
          break;
        case EChartTypes.currentUserCampaignActionStatesPie:
          this.handlePieData(
            data.statistics.pie.currentUserCampaignActionStates,
            'currentUserCampaignActionStatesPie'
          );
          // tell chart to update and is done loading
          this.updateFlag = true;
          this.loading = false;
          break;
        case EChartTypes.campaignActionStatesPie:
          this.handlePieData(
            data.statistics.pie.campaignActionStates,
            'campaignActionStatesPie'
          );
          // tell chart to update and is done loading
          this.updateFlag = true;
          this.loading = false;
          break;
        case EChartTypes.assetClassPie:
          this.handlePieData(data.pie.assetClass, 'assetClass');
          // tell chart to update and is done loading
          this.updateFlag = true;
          this.loading = false;
          break;
        case EChartTypes.currencyPie:
          this.handlePieData(data.pie.currency, 'currency');
          // tell chart to update and is done loading
          this.updateFlag = true;
          this.loading = false;
          break;
        case EChartTypes.regionPie:
          this.handlePieData(data.pie.region, 'region');
          // tell chart to update and is done loading
          this.updateFlag = true;
          this.loading = false;
          break;
        case EChartTypes.sectorPie:
          this.handlePieData(data.pie.gics, 'gics');
          // tell chart to update and is done loading
          this.updateFlag = true;
          this.loading = false;
          break;
        default:
          break;
      }
    }
  }

  /**
   * Process bar data to match chart model
   * @param barData Incoming bar data
   */
  private handleBarData(barData: BarData): void {
    const overArray = [];
    const underArray = [];
    // compute over/under bars percentage
    if (barData?.categories?.length) {
      for (let i = 0; i < barData.categories.length; i++) {
        // compute total count per category
        const total = barData?.categories?.length
          ? (barData.overweight[i] || 0) +
            (barData.overweightSelected[i] || 0) +
            (barData.balanced[i] || 0) +
            (barData.balancedSelected[i] || 0) +
            (barData.underweight[i] || 0) +
            (barData.underweightSelected[i] || 0)
          : 0;
        const category = barData.categories[i];
        const categoryName = (
          this.chartOptions.xAxis as Highcharts.XAxisOptions
        ).categories[i];

        // overweight count
        const over = barData.overweight[i] + barData.overweightSelected[i];
        // overweight percentage
        const overPercentage = Math.round((over / total) * 100);

        // underweight count
        const under = barData.underweight[i] + barData.underweightSelected[i];
        // underweight percentage
        const underPercentage = Math.round((under / total) * 100);
        // add to arrays
        overArray.push([
          categoryName,
          overPercentage,
          false,
          over,
          overPercentage,
          category,
        ]);
        underArray.push([
          categoryName,
          underPercentage * -1,
          false,
          under,
          underPercentage,
          category,
        ]);
      }
    }

    // set overweight in chartoptions
    const chartOptions = { ...this.chartOptions } as Highcharts.Options;
    const columnOptions0 = chartOptions
      .series[0] as Highcharts.SeriesColumnOptions;
    const columnOptions1 = chartOptions
      .series[1] as Highcharts.SeriesColumnOptions;
    columnOptions0.data = overArray;
    // set underweight in chartoptions
    columnOptions1.data = underArray;
    this.chartOptions = chartOptions;
  }

  /**
   * Process pie data to match chart data
   * @param pieData Incoming pie data
   * @param codeTableType Code table type for label look up
   * @param dataType Chart Data Type
   */
  private handlePieData(
    pieData: { key: string; value: number }[],
    dataType: string
  ): void {
    const hasNegativePie = pieData && pieData.some((o: any) => +o.value < 0);
    const chartOptions = { ...this.chartOptions } as Highcharts.Options;
    if (pieData == null) {
      pieData = [];
    }
    if (hasNegativePie) {
      chartOptions.chart.type = 'column';
      chartOptions.legend = { enabled: false };
      chartOptions.xAxis = {
        title: {
          text: '',
        },
        categories: pieData.map(({ key }) =>
          this.getLabelOfEntry(dataType, key)
        ),
        visible: true,
      };
      chartOptions.yAxis = {
        title: {
          text: '%',
        },
        min:
          pieData.reduce((prev, curr) => Math.min(prev, curr.value), 0) * 100,
        max:
          pieData.reduce((prev, curr) => Math.max(prev, curr.value), 0) * 100,
      };
      chartOptions.plotOptions.column = {
        stacking: 'normal',
        colors: chartOptions.plotOptions.pie.colors,
        point: {
          events: {},
        },
      };
      chartOptions.tooltip = {
        // need to disable lint rule since this is the only way to do it
        // eslint-disable-next-line object-shorthand
        formatter: function () {
          // the scope "this" shows to the formatter not the component
          const self: any = this;
          return `<span color="${self.color}">●${
            self.key
          }</span>: <strong>${self.y.toFixed(2)}%</strong><br/>`;
        },
      };
      const colors = chartOptions.plotOptions.pie.colors;
      chartOptions.series = [
        {
          type: 'column',
          name: 'currency',
          color: colors[0],
          data: pieData.map(({ key, value }, idx) => ({
            name: this.getLabelOfEntry(dataType, key),
            y: value * 100,
            color: colors[idx % colors.length],
          })),
        },
      ];
    } else {
      chartOptions.chart.type = 'pie';
      chartOptions.xAxis = {
        title: {
          text: '',
        },
        visible: false,
      };
      chartOptions.yAxis = {
        title: {
          text: '',
        },
        min: undefined,
        max: undefined,
      };
      chartOptions.legend = { enabled: true };
      delete chartOptions.plotOptions.column;
      chartOptions.tooltip = {
        // need to disable lint rule since this is the only way to do it
        // eslint-disable-next-line object-shorthand
        formatter: function () {
          // the scope "this" shows to the formatter not the component
          const self: any = this;
          return `<span color="${self.color}">●${self.key}</span>: <strong>${(
            self.y * 100
          ).toFixed(2)}%</strong><br/>`;
        },
      };
      chartOptions.series = [
        {
          type: 'pie',
          name: '5',
          data: pieData.map((point: IPieChartDataPoint) => [
            this.getLabelOfEntry(dataType, point.key),
            point.value,
          ]),
        },
      ];
    }
    this.chartOptions = chartOptions;
  }

  private getLabelOfEntry(type: string, key: string): string {
    switch (type) {
      case 'assetClass':
        return this.assetClasses.find((ac) => ac.ident === key).name;
      case 'country':
        return this.countries.find((c) => c.ident === key).name;
      case 'gender':
        return this.genders.find((g) => g.ident === key).name;
      case 'gics':
        return this.gics.find((g) => g.ident === key).name;
      case 'region':
        return this.regions.find((r) => r.ident === key).name;
      case 'currency':
        return this.currencies.find((c) => c.ident === key).name;
      case 'portfolioSuitabilities':
      case 'actionSuitabilities':
        return this.translateService.instant(key.toLowerCase());
      case 'currentUserCampaignActionStatesPie':
      case 'campaignActionStatesPie':
        if (key === CampaignActionStatus.PENDING && this.campaign) {
          const isClosedCampaign =
            CampaignStatus.CLOSED === this.campaign.status ||
            CampaignStatus.TERMINATED === this.campaign.status;
          return this.translateService.instant(
            isClosedCampaign ? 'notSent' : 'pending'
          );
        }
        return this.translateService.instant(key.toLowerCase());
      default:
        return 'N/A';
    }
  }

  /**
   * Set specific options for product pies
   */
  private setProductPieOptions() {
    this.chartOptions.legend = this.productPiesLegendOptions;
    this.chartOptions.plotOptions.pie.size = 250;
    this.chartOptions.chart.height = 500;
    this.chartOptions.plotOptions.pie.center = ['50%', '30%'];
  }
}
