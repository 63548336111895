import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AgGridEvent,
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GetLocaleTextParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ModelUpdatedEvent,
  PaginationChangedEvent,
  RowGroupingDisplayType,
  RowGroupOpenedEvent,
  SideBarDef,
} from 'ag-grid-community';
import {DataSource, GridDataProvider} from './data-source';
import {TableStateUtil} from 'src/app/util/grid/table-state.util';
import {TranslateService} from '@ngx-translate/core';
import {GridColDef, GridFilterModel} from "../../models/grid.model";
import {BehaviorSubject} from "rxjs";
import {GlobalService} from "../../services/global.service";

export interface GridResetEvent {
  api: GridApi;
}

@Component({
  selector: 'app-grid',
  templateUrl: 'grid.component.html',
})
export class GridComponent implements OnDestroy, OnInit {
  @HostBinding('class.app-grid') theme = true;
  /**
   * Grid options
   */
  private _gridOptions: GridOptions;
  /**
   * We are shadowing the gridOptions property becase we need to update the sidebar components, doing it
   * only in initialization doesn't work since it's a bounded property
   * @param value
   */
  @Input() set gridOptions(value: GridOptions) {
    this._gridOptions = {
      ...this._gridOptions,
      ...value,
    };
    this.updateSidebars();
  }

  get gridOptions(): GridOptions {
    return this._gridOptions;
  }
  /**
   * Whether or not to allow text to be selectable
   */
  @Input() enableCellTextSelection = true;
  /**
   * Data
   */
  @Input() data?: GridDataProvider;
  /**
   * Static row data
   */
  @Input() rowData?: object[];
  /**
   * Column definition for grid
   */
  @Input() columnDefs: GridColDef[];
  /**
   * Column definition for auto-group column
   */
  @Input() autoGroupColumnDef: GridColDef;
  /** Customise the parameters provided to the `groupRowRenderer` component.     */
  @Input() groupRowRendererParams: any;
  /** Specifies how the results of row grouping should be displayed.
   *
   *  The options are:
   *
   * - `'singleColumn'`: single group column automatically added by the grid.
   * - `'multipleColumns'`: a group column per row group is added automatically.
   * - `'groupRows'`: group rows are automatically added instead of group columns.
   * - `'custom'`: informs the grid that group columns will be provided.
   */
  @Input() groupDisplayType: RowGroupingDisplayType | undefined;

  /**
   * Whether or not to enable row multi select with click
   */
  @Input() rowMultiSelectWithClick = false;
  /**
   * Row selection
   */
  @Input() rowSelection?: string;
  /**
   * Rows per page
   */
  @Input() paginationPageSize = '';
  /**
   * Whether or not to have auto size depending on height of grid (overrides paginationPageSize)
   */
  @Input() paginationAutoPageSize = false;
  /**
   *  Number of rows that are being requested per API call
   */
  @Input() cacheBlockSize?: number;
  /**
   * DomLayout property
   * Note: Don't use Grid Auto Height when displaying large numbers of rows, as this will negatively impact performance.
   */
  @Input() domLayout?: 'normal' | 'autoHeight' | 'print' | undefined;
  /**
   * Whether or not pagination is enabled
   */
  @Input() pagination = true;

  /**
   * The id of the table used to save the state
   */
  @Input() tableId: string;
  /**
   * Grid Sidebar
   */
  @Input() sideBar?: SideBarDef;
  /**
   * Whether to show default sidebar
   */
  @Input() disableSidebar = false;
  /**
   * Pagination size options
   */
  @Input() paginationOptions = [
    { key: 'auto', label: this.translateService.instant('auto') },
    { key: '50', label: this.translateService.instant('50') },
    { key: '100', label: this.translateService.instant('100') },
    { key: '200', label: this.translateService.instant('200') },
    { key: '500', label: this.translateService.instant('500') },
    { key: '1000', label: this.translateService.instant('1000') },
  ];
  @Input() hideFooter = false;

  @Input() showSearch = false;
  @Input() searchPlaceholder: string | undefined = undefined;

  @Input()
  get searchValue(): string {
    return this._searchValue;
  }
  set searchValue(value: string) {
    if (value !== this._searchValue) {
      this._searchValue = value;
      this.refresh()
    }
  }
  private _searchValue: string;
  /**
   * Enables or disables grid-filter
   */
  private _gridFilterEnabled = true;
  @Input() set gridFilterEnabled(value: boolean) {
    this._gridFilterEnabled = value;
  }
  get gridFilterEnabled(): boolean {
    return this._gridFilterEnabled && this.featureDialogFilters && this.columnDefs?.some(col => col.floatingFilter == true);
  }
  /**
   * Label references for selected count and the count itself
   */
  @Input() labelRefs: {
    singular: string;
    plural: string;
  };
  @Input() selectionCount: number;
  /**
   * Whether grid-filter should have top padding or none. Default is top and bottom padding.
   * Also affects the grid height (dialog-filter) to compensate for the padding.
   * If grid-filter is disabled, the dialog-filter height is set to 100%.
   * In cards to have full padding, use gridFilterFullPadding. (Default is no top padding)
   */
  @Input() noGridFilterTopPadding = false;
  @Input() noGridFilterPadding = false;
  // only used for cards
  @Input() gridFilterFullPadding = false;
  get dialogFilterHeightAndPaddingClass(): string | null {
    if (this.noGridFilterTopPadding) {
      return 'no-grid-filter-top-padding';
    } else if (this.noGridFilterPadding) {
      return 'no-grid-filter-padding';
    } else if (this.gridFilterFullPadding) {
      return 'grid-filter-full-padding';
    } else if (!this.gridFilterEnabled) {
      return 'grid-filter-disabled';
    } else {
      return null;
    }
  }
  /**
   * Columns that are shown in the filter, even if they are hidden
   */
  @Input() importantColumns: string[] | undefined;
  /**
   * Used for clearing the selection
   */
  @Output() selectAllClicked = new EventEmitter<boolean>();
  /**
   * Output of grid ready event
   */
  @Output() gridReadyEvent: EventEmitter<GridReadyEvent> =
    new EventEmitter<GridReadyEvent>();

  @Output() gridFiltersReset: EventEmitter<GridResetEvent> =
    new EventEmitter<GridResetEvent>();

  @Output() gridFiltersChanged: EventEmitter<FilterChangedEvent> =
    new EventEmitter<FilterChangedEvent>();

  @ViewChild('sidebar_columns_trigger', {read: ElementRef})
  sidebarColumnsTrigger: ElementRef;

  /**
   * Subject that emits the filter model whenever gets updated, this is for children components
   */
  filterModelSubject = new BehaviorSubject<GridFilterModel>({});

  dataSource: DataSource;
  gridApi: GridApi;
  detailGridApi: GridApi;
  private detailGridOptions: any;
  paginationPageSizeNumber = 20;
  protected tableStateUtil: TableStateUtil;
  floatingFiltersHeight: number | undefined = 0;
  sidebarEnabled = true;

  /**
   * Default sidebar options
   */
  defaultSideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: this.translateService.instant('columns'),
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: false,
          suppressColumnExpandAll: true,
          suppressColumnMove: false,
        },
      },
    ],
    position: 'right',
  };

  featureDialogFilters: boolean;
  private expandedRows: string[] = [];

  constructor(
    injector: Injector,
    protected translateService: TranslateService,
    protected readonly globalService: GlobalService,
  ) {
    this.featureDialogFilters = true;
    if (!this.featureDialogFilters) {
      this.floatingFiltersHeight = undefined;
    }
    this.tableStateUtil = new TableStateUtil(injector);
  }

  ngOnInit(): void {
    if (!this.paginationPageSize) {
      this.paginationPageSize = this.tableStateUtil.loadPageSize(this.tableId);
    }
    if (this.paginationPageSize === 'auto') {
      this.paginationAutoPageSize = true;
    } else {
      this.paginationPageSizeNumber = parseInt(this.paginationPageSize, 10);
    }

    if (!this.cacheBlockSize) {
      this.cacheBlockSize = Math.max(100, this.paginationPageSizeNumber);
    }
    const filterModelSubject = this.filterModelSubject;
    const filtersChanged = this.gridFiltersChanged;
    // using self to access this inside the gridOptions
    const self = this;
    this.gridOptions = {
      onColumnMoved(event: ColumnMovedEvent) {
        if (event.finished) {
          self.saveColumnState();
        }
      },
      onColumnVisible(_) {
        self.saveColumnState();
      },
      onColumnResized: (event: ColumnResizedEvent) => {
        if (['uiColumnDragged', 'uiColumnResized'].includes(event.source) && event.finished) {
          self.saveColumnState();
        }
      },
      enableCellTextSelection: this.enableCellTextSelection,
      onFilterChanged(event: FilterChangedEvent) {
        filterModelSubject.next(event.api.getFilterModel());
        filtersChanged.emit(event);
      },
      gridId: this.tableId,
      ...this.gridOptions,
    };
    this.detailGridOptions =
      this.gridOptions.detailCellRendererParams?.detailGridOptions;
    this.initializeDataSource();
  }

  ngOnDestroy(): void {
    this.tableStateUtil?.destroy();
  }

  initializeDataSource(initDataSource = false) {
    if (this.data) {
      this.dataSource = new DataSource(
        this.tableStateUtil,
        this.tableId,
        this.data as GridDataProvider,
        this.globalService,
        () => this._searchValue,
      );
      if (initDataSource) {
        this.dataSource.initDatasource(this.gridApi);
      }
    }
  }

  updateDataSource(data: GridDataProvider, initDataSource: boolean = false) {
    this.data = data;
    this.dataSource = new DataSource(
      this.tableStateUtil,
      this.tableId,
      this.data as GridDataProvider,
      this.globalService,
      () => this._searchValue,
    );
    if (initDataSource && this.gridApi) {
      this.dataSource.initDatasource(this.gridApi);
    }
  }

  /**
   * translate ag grid text
   */
  getLocaleText = (params: GetLocaleTextParams) =>
    this.translateService.instant(params.key);

  refresh(purgeData: boolean = false, redrawRows: boolean = false): void {
    if (this.data) {
      this.gridApi.refreshServerSide( { purge: purgeData } );
      // on purge no redraw is required
      if (!purgeData && redrawRows) {
        this.gridApi.addEventListener('storeRefreshed', this.onStoreRefreshed.bind(this));
      }
    }
  }

  /**
   * Redraws currently visible rows, updating custom cell renderers.
   * Adding and removing event listeners is lightweight, and has no performance impact if not done too often.
   */
  private onStoreRefreshed(): void {
      this.gridApi.redrawRows();
      this.gridApi.removeEventListener('storeRefreshed', this.onStoreRefreshed.bind(this));
  }

  /**
   * Function triggered when GRID loading is complete
   * @param params Grid Ready Event
   */
  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
    this.gridReadyEvent.emit(params);
    if (this.data) {
      if (!this.dataSource) {
        this.initializeDataSource();
      }
      this.dataSource.initDatasource(params.api);
    }
    // register a URL listener to read the state from the URL
    if (this.tableId) {
      this.tableStateUtil.observeFor(
        this.tableId,
        params.api,
      );
    }
    // required for restoring the grid state as soon as the grid is ready
    const columnState = this.getColumnState();
    if (columnState) {
      params.api.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }
    this.sidebarEnabled = this.gridApi.isSideBarVisible();

    // DETAIL GRID - Add Functionality to save detail col state
    if (this.detailGridOptions && !this.disableSidebar) {
      this.detailGridOptions.onGridReady = (gridReadyEvent: GridReadyEvent) => {
        this.detailGridApi = gridReadyEvent.api;
        const detailColumnState = this.getColumnState(this.detailGridOptions.columnDefs);
        if (detailColumnState) {
          gridReadyEvent.api.applyColumnState({
            state: detailColumnState,
            applyOrder: true,
          });
        }
        // register column update listeners for the detail grid
        setTimeout(() => {
          gridReadyEvent.api.addEventListener('columnVisible', (event) => {
            this.saveColumnState(
              event.api,
              this.detailGridOptions.columnDefs
            );
          });
          gridReadyEvent.api.addEventListener('columnMoved', (event) => {
            this.saveColumnState(
              event.api,
              this.detailGridOptions.columnDefs
            );
          });
          gridReadyEvent.api.addEventListener('columnResized', (event) => {
            if (['uiColumnDragged', 'uiColumnResized'].includes(event.source)) {
              this.saveColumnState(
                event.api,
                this.detailGridOptions.columnDefs
              );
            }
          });
        });
      };
    }
  }

  onRowGroupOpened(event: RowGroupOpenedEvent): void {
    const index = this.expandedRows.indexOf(event.node.id);
    if (event.expanded && index < 0) {
      this.expandedRows.push(event.node.id);
    } else if (!event.expanded && index >= 0) {
      this.expandedRows.splice(index, 1);
    }
  }

  onModelUpdated(event: ModelUpdatedEvent): void {
    // resize columns to fit if no state is saved
    if (!this.getColumnState()) {
      event.api.sizeColumnsToFit();
    }

    const iconColumns = event.api
        .getColumns()
        .filter((col) => col.getColId().includes('icon'))
        .map((col) => col.getColId());
    event.api.autoSizeColumns(iconColumns, true);
    this.expandedRows.forEach((rowId) => {
      this.gridApi.getRowNode(rowId)?.setExpanded(true);
    });
  }

  resetExpandedRows(): void {
    this.expandedRows.splice(0, this.expandedRows.length);
  }

  reload(): void {
    // trigger reloading full table
    if (this.gridApi != null && this.dataSource) {
      this.gridApi.refreshServerSide({ purge: true });
    }
  }

  onPaginationChanged(event: PaginationChangedEvent): void {
    if (event.newPage) {
      this.tableStateUtil.saveTableStateFromApi(event.api);
    }
  }

  sortAndFilterListener(event: AgGridEvent<any>): void {
    this.tableStateUtil.saveTableStateFromApi(event.api);
  }

  adjustPage(event: FirstDataRenderedEvent): void {
    //-begin Automatically reset column widths if no state is saved
    const colsState = this.getColumnState();
    if (!colsState) {
      this.resetColWidths();
    }
    //-end
    this.tableStateUtil.adjustPageIfNeeded(event.api);
  }

  resetColWidths(): void {
    this.gridApi.autoSizeAllColumns();
    this.saveColumnState();
  }

  resetGrid(): void {
    this.tableStateUtil.clearColumnState(this.tableId, this.columnDefs);
    // reset the detail grid columns state if present
    if (this.detailGridOptions && this.detailGridApi) {
      this.detailGridApi.resetColumnState();
    }
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
    this.gridApi.setPivotColumns([]);
    this.gridApi.resetColumnState();

    this.gridFiltersReset.emit({ api: this.gridApi });
  }

  onPaginationChange(): void {
    if (this.paginationPageSize === 'auto') {
      this.paginationAutoPageSize = true;
      this.paginationPageSizeNumber = 20;
    } else {
      this.paginationAutoPageSize = false;
      this.paginationPageSizeNumber = parseInt(this.paginationPageSize, 10);
    }
    this.cacheBlockSize = Math.max(100, this.paginationPageSizeNumber);
    this.gridApi.onFilterChanged();
    this.tableStateUtil.savePageSize(this.tableId, this.paginationPageSize);
  }

  private getColumnState(colDefs: ColDef[] = this.columnDefs) {
    return this.tableStateUtil.getColumnState(this.tableId, colDefs);
  }

  private saveColumnState(gridApi: GridApi = this.gridApi, colDefs: ColDef[] = this.columnDefs) {
    this.tableStateUtil.saveColumnState(this.tableId, gridApi, colDefs)
  }

  protected readonly undefined = undefined;

  /**
   * Updates the gridOptions to enable the default sideBar if defined, we don't define sidebars in any place
   * in the application, here we overwrite such options if the sidebar is not disabled
   * @private
   */
  private updateSidebars() {
    // INIT Sidebars if enabled
    if (this.sideBar) {
      this._gridOptions.sideBar = this.sideBar;
      // Timeout needed for the moment, because the detail grid options are not initialized yet
      setTimeout(() => {
        if (this.detailGridOptions) {
          this.detailGridOptions.sideBar = this.sideBar;
        }
      });
    } else if (!this.disableSidebar) {
      this._gridOptions.sideBar = this.defaultSideBar;
      // Timeout needed for the moment, because the detail grid options are not initialized yet
      setTimeout(() => {
        if (this.detailGridOptions) {
          this.detailGridOptions.sideBar = this.defaultSideBar;
        }
      });
    }
  }

  clearSelection() {
    this.selectAllClicked.emit(false);
  }

  toggleColumnsMenu() {
    if (this.gridApi.isToolPanelShowing()) {
      this.gridApi.closeToolPanel();
    } else {
      this.gridApi.openToolPanel('columns');
      if (!this.sidebarColumnsTrigger) return;
      const icon = this.sidebarColumnsTrigger.nativeElement as HTMLElement;
      const sidebar = icon.parentElement.parentElement.querySelector('.ag-side-bar.ag-side-bar-right') as HTMLElement;
      const grid = icon.parentElement.parentElement.querySelector('ag-grid-angular') as HTMLElement;
      const list = sidebar.querySelector('.ag-column-select-virtual-list-container') as HTMLElement;
      const iconBounds = icon.getBoundingClientRect();
      const gridHeight = grid.clientHeight;
      const maxHeight = gridHeight * 0.8;
      const height = Math.min(list.clientHeight + 48, maxHeight);
      const style = [
        `left: ${iconBounds.x}px;`,
        `top: ${iconBounds.y - height - 5}px;`, // 5px padding
        `height: ${height}px;`,
      ].join(' ');
      sidebar.setAttribute("style", style);
      sidebar.classList.add('custom-column-side-panel');
    }
  }
}
