import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ISliderOptions } from 'src/app/models/slider-options.model';
import { WeightRange } from 'src/app/models/filter.model';
import { EFilterCategories, ERangeWeightFilter } from 'src/app/util/enum';
import {UiFilterConfigService} from "../../../services/ui-filter-config.service";
import {isFilterRangeWeightFilterActivePipe} from "../../../services/filter-utils.service";

/**
 * Range Weight Filter Component.
 * Component for combination of range and weight filter.
 */
@Component({
  selector: 'app-filter-range-weight',
  templateUrl: './filter-range-weight.component.html',
})
export class FilterRangeWeightComponent implements OnInit {
  /**
   * Component input to label
   */
  @Input() label: string;

  @Input() readOnly = false;
  /**
   * Component input to set filter value
   */
  @Input() filter: WeightRange;
  /**
   * Filter category that the filter belongs to
   */
  @Input() filterCategory: EFilterCategories; // TODO: make really configurable, this is temporarily used to make some filters unavailable
  /**
   * Component output to notifiy that filter should be deleted
   */
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @angular-slider/ngx-slider range slider options
   */
  rangeSlider: ISliderOptions;
  /**
   * Flag to mark to show/hide range slider
   */
  hasExposure = false;
  /**
   * Local value for weight select field
   */
  weightSelect: WeightRange.WeightEnum;
  /**
   * Possible weight options
   */
  weightOptions: WeightRange.WeightEnum[] = Object.values(
    WeightRange.WeightEnum
  );

  constructor(
    private readonly filterConfigService: UiFilterConfigService
  ) {}

  ngOnInit(): void {
    this.weightSelect = this.filter.weight;
    if (this.filter.range?.max == null && this.filter.range?.min == null) {
      this.rangeSlider = this.createRangeSliderConfig({
        min: 0,
        max: 100
      });
    } else {
      this.hasExposure = true;
      this.rangeSlider = this.createRangeSliderConfig({
        min: this.filter.range.min ? this.filter.range.min * 100 : 0,
        max: this.filter.range.max ? this.filter.range.max * 100 : 100,
      });
    }
    if (
      !isFilterRangeWeightFilterActivePipe(
        this.filterConfigService,
        this.fields.weight,
        this.filterCategory
      ) &&
      isFilterRangeWeightFilterActivePipe(
        this.filterConfigService,
        this.fields.exposure,
        this.filterCategory
      )
    ) {
      this.hasExposure = true;
      this.filter.range.max = this.rangeSlider.maxValue / 100; // 0%
      this.filter.range.min = this.rangeSlider.minValue / 100; // 100%
      this.filter.weight = undefined;
    }
    this.filter = {...this.filter};
  }

  /**
   * Provides range weight filter enum to html
   */
  get fields() {
    return ERangeWeightFilter;
  }

  /**
   * Change handler for @angular-slider/ngx-slider range filter
   * @param ev Event
   */
  onRangeSliderChange(ev: Event): void {
    const target = ev.target as HTMLInputElement;
    const parent = target.parentElement;
    const value = +target.value;
    const isMin = parent.children.item(0) == target;
    if (isMin) {
      this.filter.range = {
        ...this.filter.range,
        min: value / 100
      };
      this.rangeSlider = {
        ...this.rangeSlider,
        min: value,
      };
    } else {
      this.filter.range = {
        ...this.filter.range,
        max: value / 100
      };
      this.rangeSlider = {
        ...this.rangeSlider,
        max: value,
      };
    }
    this.filter = {...this.filter};
  }

  /**
   * Change handler for filter with select field
   */
  onWeightSelectChange(): void {
    this.filter.weight = this.weightSelect;
  }

  /**
   * Generate initial @angular-slider/ngx-slider options
   * @param cfg Config
   */
  private createRangeSliderConfig(cfg: ISliderOptions): ISliderOptions {
    return {
      minValue: 0,
      maxValue: 100,
      step: 1,
      disabled: this.readOnly,
      formatLabel: (value: number): string => value + '%',
      ...cfg
    };
  }

  /**
   * Toggles hasExposure flag
   */
  toggleHasExposure(): void {
    this.hasExposure = !this.hasExposure;
    if (this.hasExposure) {
      this.filter.range.max = this.rangeSlider.maxValue / 100;
      this.filter.range.min = this.rangeSlider.minValue / 100;
      this.filter.weight = undefined;
    } else {
      this.filter.range.max = null;
      this.filter.range.min = null;
    }
  }

  /**
   * Click handler on delete icon
   */
  onDeleteClick(): void {
    this.delete.emit();
  }

  /**
   * Helper funtion to track item during iteration over items
   * @param index Index of item
   * @param item Iterated item
   */
  trackByFn(index: number, item: any): number {
    return index;
  }
}
